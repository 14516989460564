import React, { useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import {
  FaMapMarkerAlt,
  FaMobileAlt,
  FaRegClock,
  FaRegMoneyBillAlt,
} from "react-icons/fa";
import { FaLocationCrosshairs, FaPencil, FaRegTrashCan } from "react-icons/fa6";
import { BsSearch } from "react-icons/bs";
import { API } from "../apiwrapper";
import apiURl from "../../Store/Action/api-url";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SetpopupReducerData } from "../../Store/reducer/PopupReducer";
import { toast } from "react-toastify";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addItem, removeItem, setUserCartData } from "../../Store/reducer/Cart";
import { BiX } from "react-icons/bi";
import { FaMinus } from "react-icons/fa";
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { BASECONFIG } from "../../config";

function HandymanCheckout() {
  const [show, setShow] = useState(false);
  const [location, setLocation] = useState(false);
  const [editAddress, seteditAddress] = useState(false);

  const [selectAddress, setSelectAddress] = useState(false);
  const [getaddress, setGetAddress] = useState([]);
  const [cartData, setCartData] = useState([]);
  let token = localStorage.getItem("token");
  console.log(getaddress, "getaddress");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);

  const [countryAddress, setcountryAddress] = useState('');
  const [stateAddress, setstateAddress] = useState('');
  const [houseNumberAddress, sethouseNumberAddress] = useState('');
  const [zipcodeAddress, setzipcodeAddress] = useState('');
  const [address, setAddress] = useState('');


  const [selectedDate, setSelectedDate] = useState(new Date());
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  // const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(true);
  console.log(time, "datetime");
  // const { user = {} } = useSelector((state) => state);

  const user = useSelector((state) => state?.user);
  const price = useSelector((state) => state?.user);
  const dispatch = useDispatch();
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [servicePrice, setServicePrice] = useState(0);
  const [serviceTax, setServiceTax] = useState(0);
  console.log(selectedAddress, "4778887")



  const deepCopy = (obj) => {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map(deepCopy);
    }

    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key, deepCopy(value)])
    );
  };


  const handleIncrement = (e, index, cart) => {
    // const updatedCartData = [...cartData];
    const updatedCartData = deepCopy(cartData);
    console.log("cardadaddada", updatedCartData)

    updatedCartData[index]["ServiceQuantity"] = updatedCartData[index]["ServiceQuantity"] + 1;
    updatedCartData[index]["Price"] = (updatedCartData[index]["Price"] / (updatedCartData[index]["ServiceQuantity"] - 1)) * updatedCartData[index]["ServiceQuantity"];
    updatedCartData[index]["TotalPrice"] = updatedCartData[index]["Price"] + updatedCartData[index]["Tax"];
    setCartData(updatedCartData);

    console.log("cartData", updatedCartData);
  };

  const handleDecrement = (e, index) => {
    // const updatedCartData = [...cartData];
    const updatedCartData = deepCopy(cartData);

    if (updatedCartData[index]["ServiceQuantity"] > 1) {

      updatedCartData[index]["ServiceQuantity"] = updatedCartData[index]["ServiceQuantity"] - 1;
      updatedCartData[index]["Price"] = (updatedCartData[index]["Price"] / (updatedCartData[index]["ServiceQuantity"] + 1)) * updatedCartData[index]["ServiceQuantity"];
      setCartData(updatedCartData);
    }
  };

  const getAddress = async () => {

    try {
      await API({
        url: `${apiURl.getuseraddress}`,
        method: "GET",
        headers: {
          Authorization: `${token}`,
          Accept: "application/json",
        },
      }).then((data) => {
        if (data?.status == true) {
          // console.log(data, "667666667");
          setGetAddress(data);
          setcountryAddress();
          setstateAddress();
          sethouseNumberAddress();
          setzipcodeAddress();
          setAddress();
        } else {
          setGetAddress([]);
        }
      });
    } catch (error) {
      throw error;
    }
  };

  const getCartData = async () => {
    try {
      await API({
        url: `${apiURl.cartlisting}`,
        method: "GET",
        headers: {
          Authorization: `${token}`,
          Accept: "application/json",
        },
      }).then((data) => {
        if (data?.status) {
          console.log(data?.result, "getdatata")
          setCartData(data?.result);
          // dispatch(addItem(data?.result));
          dispatch(setUserCartData(data?.result));



        } else {
          setCartData([]);
        }
      });
    } catch (error) {
      throw error;
    }
  };



  const handleShowLogin = () => {
    if (!user?.data?.status) {
      dispatch(
        SetpopupReducerData({
          modalType: "LOGIN",
          showModal: true,
        })
      );
    }
  };

  useEffect(() => {
    getCartData();
  }, []);

  const fetchCountries = async () => {
    try {
      await API({
        url: `${apiURl.countries}`,
        method: "GET",
      }).then((data) => {
        if (data?.status) {
          setCountries(data?.data);
        } else {
          setCountries([]);
        }
      });
    } catch (error) {
      // throw error;
      toast.error(error);
    }
  };

  const fetchAddressState = async (addressCountryId, type) => {
    try {
      await API({
        url: `${apiURl.stateLists}/${addressCountryId}`,
        method: "GET",
      }).then((data) => {
        if (data?.status) {
          if (type === "address") {
            setStates(data?.data);
          } else {
          }
        } else {
          setStates([]);
        }
      });
    } catch (error) {
      // throw error;

      toast.error("error");
    }
  };
  useEffect(() => {
    fetchCountries();
  }, [])

  useEffect(() => {
    if (countryAddress) {
      fetchAddressState(countryAddress, "address");
    }
  }, [countryAddress]);





  const handleaddAddress = async (e) => {

    if (!selectedAddressId) {

      const payload = {
        CountryId: countryAddress,
        StateId: stateAddress,
        HouseNo: houseNumberAddress,
        ZipCode: zipcodeAddress,
        AddressType: 1,
        StreetAddress: address
      }


      try {
        await API({
          url: `${apiURl.adduseraddress}`,
          method: "POST",
          headers: {
            Authorization: `${user?.token}`
          },
          body: payload,
        }).then((data) => {

          console.log(data, "datadatadata")
          if (data?.status == true) {
            toast.success(data?.message);
            getAddress();
            setLocation(false);
            // setcountryAddress();
            // setstateAddress();
            // sethouseNumberAddress();
            // setzipcodeAddress();
            // setAddress();
          } else {
            toast.error(data?.error);
          }
        });
      } catch (error) {
        toast.error(error);
      }

    }

    else {
      const payload = {
        CountryId: countryAddress,
        StateId: stateAddress,
        HouseNo: houseNumberAddress,
        ZipCode: zipcodeAddress,
        AddressType: 1,
        StreetAddress: address,
        Id: 8,

      }


      try {
        await API({
          url: `${apiURl.editaddress}/${selectedAddressId}`,
          method: "PUT",
          headers: {
            Authorization: `${user?.token}`
          },
          body: payload,
        }).then((data) => {

          console.log(data, "datadatadata")
          if (data?.status == true) {
            toast.success(data?.message);
            getAddress();
            seteditAddress(false);
            // setcountryAddress();
            // setstateAddress();
            // sethouseNumberAddress();
            // setzipcodeAddress();
            // setAddress();
          } else {
            toast.error(data?.error);
          }
        });
      } catch (error) {
        toast.error(error);
      }
    }

  }


  const handleIconClick = () => {
    setIsDatePickerOpen(true);
  };
  const handleChange = (date) => {
    setSelectedDate(date);
    setIsDatePickerOpen(false);
    // const dateOnly = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    // const dateOnly = new Date(date.getFullYear(), date.getMonth(), date.getDate());

    const formatMonth = (month) => {
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      return monthNames[month];
    };

    const formattedDate = `${formatMonth(date.getMonth())} ${date.getDate()} ${date.getFullYear()}`;
    // const timeOnly = new Date(0, 0, 0, date.getHours(), date.getMinutes());
    const timeString = `${date.getHours()}:${date.getMinutes()}`;

    setDate(formattedDate);
    setTime(timeString);

  };



  const handleEditClick = (addressId) => {
    console.log("addrseeseseid", addressId)
    setSelectedAddressId(addressId);

    const addressToEdit = getaddress.result.find((addr) => addr.Id === addressId);

    // Populate the modal fields with the existing address data
    if (addressToEdit) {
      setcountryAddress(addressToEdit.CountryId);
      setstateAddress(addressToEdit.StateId);
      sethouseNumberAddress(addressToEdit.HouseNo);
      setzipcodeAddress(addressToEdit.ZipCode);
      setAddress(addressToEdit.StreetAddress);
    }

    seteditAddress(true);
  };


  const handleDeleteClick = async (id) => {
    const payload = {
      Status: 0,


    }

    try {
      await API({
        url: `${apiURl.editaddress}/${id}`,
        method: "PUT",
        headers: {
          Authorization: `${user?.token}`
        },
        body: payload,
      }).then((data) => {

        console.log(data, "datadatadata")
        if (data?.status == true) {
          toast.success(data?.message);
          getAddress();
          seteditAddress(false);
          // setcountryAddress();
          // setstateAddress();
          // sethouseNumberAddress();
          // setzipcodeAddress();
          // setAddress();
        } else {
          toast.error(data?.error);
        }
      });
    } catch (error) {
      toast.error(error);
    }
  }

  const handleProceed = () => {
    if (selectedAddress) {
      console.log("Selected Address122222: ", selectedAddress);
      setShow(false);
    }
  };


  useEffect(() => {
    const totalPrice = cartData.reduce((acc, cart) => acc + (cart?.Price || 0), 0);
    const totalTax = cartData.reduce((acc, cart) => acc + (cart?.Tax || 0), 0);

    setServiceTax(totalTax)
    setServicePrice(totalPrice);

  }, [cartData]);


  const deleteItem = async (e, Id) => {

    // console.log(Id,"IdIdIdIdIdIdIdIdIdIdId")
    try {
      await API({
        url: `${apiURl.deletecart}/${Id}`,
        method: "DELETE",
        headers: {
          Authorization: `${user?.token}`
        },
      }).then((data) => {

        console.log(data, "deleteItem")
        if (data?.status == true) {
          toast.success(data?.message);
          getCartData();
        } else {
          toast.error(data?.error);
        }
      });
    } catch (error) {
      toast.error(error);
    }

  }

  const handleSub = async () => {

    if (!selectedAddress) {
      toast.error("Please select an address.");
      return;
    }

    if (!date || !time) {
      toast.error("Please select a time slot.");
      return;
    }

    let abc = [];
    cartData.map((ele, index) => {
      abc.push({
        "ServiceDetailsId": ele?.ServiceDetailsId,
        "Price": ele?.Price,
        "ServiceQuantity": ele?.ServiceQuantity,
        "TotalPrice": ele?.TotalPrice,
        "Tax": ele?.Tax,
        "cartID": ele?.Id,

      });
    })

    console.log(cartData, "shhsjshsjhsh")
    const payload = {
      number: "9811852056",
      Address: selectedAddress,
      ServiceDate: date,
      ServiceTime: time,
      Price: servicePrice,
      Tax: serviceTax,
      TransactionStatus: "1",
      DiscountPrice: 5.00,
      TotalPrice: servicePrice + serviceTax,
      cartData: abc,


    }

    try {
      await API({
        url: `${apiURl.order}`,
        method: "POST",
        headers: {
          Authorization: `${user?.token}`
        },
        body: payload,
      }).then(async (data) => {

        console.log(data, "orderdata")
        if (data?.status == true) {
          // toast.success(data?.message);
          //  console.log(data?.data?.OrdersData?.OrderNumber,"111111111");
          try {
            const config = {
              headers: {
                Authorization: `${user?.token}`
              }
            };

            const response = await axios.post(
              `${BASECONFIG.BASE_URL}/create-checkout-session`,
              {
                amount: servicePrice + serviceTax,
                name: data?.data?.OrdersData?.OrderNumber,
                orderId: data?.data?.OrdersData?.Id,
                TransactionStatus: 2,
              },
              config
            );


            const sessionId = response.data.result;
            const stripe = await loadStripe('pk_test_51NTLrUSHjjlpntOIZnXpupdNw6POlUwrEstaQmSa6eZKCZmQ4tflBwSAQJb1XeUmdtzsjY1QsGRsYfZug1hiomDF001pxISeYl');
            const { error } = stripe.redirectToCheckout({
              sessionId: sessionId,
            });

            if (!error) {


              console.log("paymentresponsepaymentresponse");

              getCartData();

            }
            else {
              console.error('Error redirecting to Checkout:', error);
              // toast.error(data?.error);

            }
          } catch (error) {
            console.error('Error initiating payment:', error);
            // toast.error(data?.error);

          }

        } else {
          toast.error(data?.error);
        }
      });
    } catch (error) {
      toast.error(error);
    }

  }



  // const handlePaymentInitiation = async () => {
  //   try {
  //     const config = {
  //       headers: {
  //         Authorization: `${user?.token}`
  //       }
  //     };

  //     const response = await axios.post(
  //       'http://192.168.20.186:8004/api/create-checkout-session', 
  //       {
  //         amount: servicePrice + serviceTax,
  //         name:"ashish",
  //         orderId:"1111", 
  //        TransactionStatus:1,
  //       },
  //       config  
  //     );

  //     const sessionId = response.data.result;

  //     // Redirect to Stripe Checkout
  //     const stripe = await loadStripe('pk_test_51NTLrUSHjjlpntOIZnXpupdNw6POlUwrEstaQmSa6eZKCZmQ4tflBwSAQJb1XeUmdtzsjY1QsGRsYfZug1hiomDF001pxISeYl');
  //     const { error } = await stripe.redirectToCheckout({
  //       sessionId: sessionId,
  //     });

  //     if (error) {
  //       console.error('Error redirecting to Checkout:', error);
  //     }
  //   } catch (error) {
  //     console.error('Error initiating payment:', error);
  //   }
  // };


  return (
    <>
      <div className="py-5">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-xl-6">
              {!user?.isAuthenticated ? (
                <>
                  <div className="borderBox mb-4">
                    <h3>Account</h3>
                    <p>To book the service, please login or sign up</p>
                    {/* <Link to="/login"> */}
                    <button
                      className="btnLogin"
                      data-toggle="modal"
                      data-target="/login"
                      onClick={handleShowLogin}
                    >
                      Login
                    </button>
                    {/* <button className="btnLogin">Login </button>
                    </Link> */}
                  </div>
                </>
              ) : (
                <div>
                  <div className="borderBox  mb-4">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h3>Send booking details to</h3>
                        <p className="m-0">{user?.UserDettails?.Mobile && user?.UserDettails?.Mobile}</p>
                      </div>
                      <span className="fontIcon">
                        <FaMobileAlt />
                      </span>
                    </div>
                  </div>

                  <div className="borderBox  mb-4">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h3>Address</h3>
                        <p> {selectedAddress && selectedAddress}</p>

                        <button
                          onClick={() => {
                            setShow(!show);
                            getAddress();
                            setSelectedAddress();
                          }}
                          className="btnLogin  px-4"
                        >
                          Select an Address
                        </button>
                      </div>
                      <span className="fontIcon">
                        <FaMapMarkerAlt />
                      </span>
                    </div>
                  </div>

                  {/* <div className="borderBox  mb-4">
                    <div className="d-flex justify-content-between align-items-center">
                      <h3>Slot</h3>
                      <span className="fontIcon">
                        <FaRegClock />
                      </span>
                    </div>
                  </div> */}

                  <div className="borderBox mb-4">
                    <div className="d-flex justify-content-between align-items-center">
                      <h3>Slot</h3>
                      <span className="fontIcon" >
                        <FaRegClock onClick={handleIconClick} />
                      </span>
                    </div>

                    {/* Date and Time Picker */}
                    {/* {isDatePickerOpen && ( */}
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleChange}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      className="form-control"
                    />
                    {/* )} */}
                  </div>
                  {/* <div className="borderBox  mb-4">
                    <div className="d-flex justify-content-between align-items-center">
                      <h3>Place Order</h3>
                      <span className="fontIcon">
                        <FaRegMoneyBillAlt onClick={handleSub} />
                      </span>
                    </div>
                  </div> */}
                  <div className="borderBox  mb-4">
                    <div className="d-flex justify-content-between align-items-center">
                      <h3>Payment Method</h3>
                      {/* <span className="fontIcon"> */}
                        <button className="custombtn"onClick={handleSub} >pay</button>
                        {/* <FaRegMoneyBillAlt  onClick={handleSub} />pay */}
                        {/* <FaRegMoneyBillAlt onClick={handlePaymentInitiation} /> */}
                      {/* </span> */}
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="col-xl-6">
              <div className="borderBox">
                {cartData.length > 0 && cartData.map((cart, index) => (
                  <div key={index} className="d-flex justify-content-between align-items-center">
                    <p className="m-0">{cart?.ServiceDetail?.ServiceDetailLanguage?.Title}</p>
                    <div className="plusMinusValue">
                      {cartData[index]["ServiceQuantity"] == 1 ?
                        <button
                          type="button"
                          className="sub"
                          onClick={(e) => deleteItem(e, cart?.Id)}
                        >

                          -
                        </button>
                        :
                        <button
                          type="button"
                          className="sub"
                          onClick={(e) => handleDecrement(e, index)}
                        >

                          -
                        </button>
                      }

                      {/* {cartData[index]["ServiceQuantity"]==1?
                        <FaMinus onClick={(e)=>deleteItem(e,cart?.Id)}/>
                        :<FaMinus  onClick={(e) => handleDecrement(e, index)}/>
                        }  */}
                      <input
                        type="text"
                        value={cart?.ServiceQuantity}
                        className="field"
                        readOnly
                      />
                      <button
                        type="button"
                        className="add"
                        onClick={(e) => handleIncrement(e, index)}
                      >
                        +
                      </button>
                    </div>
                    <BiX onClick={(e) => deleteItem(e, cart?.Id)} />

                    <p className="m-0"
                    >${cart?.Price} </p>
                  </div>
                ))}
                {/* <p>Total Price: ${servicePrice}</p> */}
                {/* <p> ToTal serviceTax :{serviceTax}</p> */}
                <hr />
                <h3 className="my-4">Payment summary</h3>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="fw-light">Item total</p>
                  <p className="amount  font-weight-bold">${servicePrice}</p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="fw-light">Taxes and Fee</p>
                  <p className="amount  font-weight-bold">${serviceTax}</p>
                </div>
                <hr />
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className="m-0">Total</h3>
                  <h3 className="font-weight-bold m-0">${servicePrice + serviceTax}</h3>
                </div>
              </div>

              <div className="borderBox  mt-4 py-4">
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className="m-0">Amount to pay</h3>
                  <h3 className="font-weight-bold m-0">${servicePrice + serviceTax}</h3>
                </div>
                {/* <div className="text-right mt-2">
                  <a className="viewBreakup" href="#">
                    View breakup
                  </a>
                </div> */}
              </div>
            </div>

          </div>
        </div>
      </div>

      <Modal show={show} onHide={() => setShow(!show)} size="md" centered>
        <Modal.Body>
          <div className="savedAddress">
            <h3>Saved address</h3>
            <ul className="selectAddress">
              {getaddress?.result?.map((ele) => (
                <>
                  {/* <li>{ele?.Address}</li> */}
                  {ele?.StreetAddress.length>0 ?
                      <li className="form-check d-flex"><label>
                    <input type="radio" class="form-check-input" name="optradio" onChange={(e) => {
                      e.preventDefault();
                      setSelectedAddress(ele?.StreetAddress);
                     }} />
                    {ele?.StreetAddress}</label> <span className="ms-auto text-muted"><FaPencil onClick={() => handleEditClick(ele.Id)} /></span><span className="ms-4 text-danger"><FaRegTrashCan onClick={() => handleDeleteClick(ele.Id)} /></span></li>
                  :
                  <>
                  <li className="form-check d-flex"><label>
                    <input type="radio" class="form-check-input" name="optradio" onChange={(e) => {
                      e.preventDefault();
                      setSelectedAddress(ele?.Address);
                     }} />
                    {ele?.Address}</label> <span className="ms-auto text-muted"><FaPencil onClick={() => handleEditClick(ele.Id)} /></span><span className="ms-4 text-danger"><FaRegTrashCan onClick={() => handleDeleteClick(ele.Id)} /></span></li>                  </>
                  }
                </>
              ))}
            </ul>
            <button onClick={() => { setLocation(!location); }} className="addAddress  mt-2 mb-4">+ Add another address</button>
            <button className="btnSub  btn" disabled={!selectedAddress}
              onClick={handleProceed}
            >
              Proceed
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={location}
        onHide={() => setLocation(!location)}
        size="md"
        centered
      >
        <Modal.Body>
          <div className="savedAddress">
            <div className="searchLocation">
              {/* <input
                type="text"
                className="inputSearch  form-control"
                placeholder="Search for your location/society/apartment"
              />
              <button className=".searchBtn">
                <BsSearch />
              </button> */}
              <h3>Add Address</h3>
              <div className='row'>
                <div className='col-md-5 mb-3'>
                  <select className='form-control' onChange={(e) => setcountryAddress(parseInt(e.target.value))} value={countryAddress} >
                    <option>Select Country</option>
                    {countries.map((country) => (
                      <option key={country?.Id} value={country?.Id}>
                        {country?.Name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='col-md-5 mb-3'>
                  <select className='form-control' onChange={(e) => setstateAddress(parseInt(e.target.value))} value={stateAddress}>
                    <option>Select State</option>
                    {states.map((st) => (
                      <option key={st?.Id} value={st?.Id}>
                        {st?.Name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='col-md-5 mb-3'>
                  <input
                    type="text"
                    className="inputSearch  form-control"
                    placeholder="House no."
                    value={houseNumberAddress}
                    onChange={(e) => sethouseNumberAddress(e.target.value)}
                  />
                </div>
                <div className='col-md-5 mb-3'>
                  <input
                    type="text"
                    className="inputSearch  form-control"
                    placeholder="Zipcode"
                    value={zipcodeAddress}
                    onChange={(e) => setzipcodeAddress(e.target.value)}
                  />
                </div>
                <div className='col-md-10 mb-3'>
                  <input
                    type="text"
                    className="inputSearch  form-control"
                    placeholder="STREET ADDRESS"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />

                </div>
              </div>
            </div>


            <button 
              // className="selectLocation"
              onClick={handleaddAddress}
              className="custombtn "

            >
              Add 
            </button>
            


            {/* <button
                                  onClick={() => setSelectAddress(!selectAddress)}
                                  className="selectLocation"
                                >
                                  <FaLocationCrosshairs /> Use Current location
                                </button> */}

            {/* <hr className=".hr" />
                                <div className="text-center">
                                  <img src="./images/powerbyGoogle.jpg" />
                                </div> */}
          </div>
        </Modal.Body>
      </Modal>



      <Modal
        show={editAddress}
        // onHide={() => seteditAddress(!editAddress)}
        onHide={() => {
          seteditAddress(!editAddress);
          setSelectedAddressId(null); // Reset the selected address id when modal is closed
        }}
        size="md"
        centered
      >
        <Modal.Body>
          <div className="savedAddress">
            <div className="searchLocation">
              <div className='row'>
                <div className='col-md-5 mb-3'>
                  <select className='form-control' onChange={(e) => setcountryAddress(parseInt(e.target.value))} value={countryAddress} >
                    <option>Select Country</option>
                    {countries.map((country) => (
                      <option key={country?.Id} value={country?.Id}>
                        {country?.Name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='col-md-5 mb-3'>
                  <select className='form-control' onChange={(e) => setstateAddress(parseInt(e.target.value))} value={stateAddress}>
                    <option>Select State</option>
                    {states.map((st) => (
                      <option key={st?.Id} value={st?.Id}>
                        {st?.Name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='col-md-5 mb-3'>
                  <input
                    type="text"
                    className="inputSearch  form-control"
                    placeholder="House no."
                    value={houseNumberAddress}
                    onChange={(e) => sethouseNumberAddress(e.target.value)}
                  />
                </div>
                <div className='col-md-5 mb-3'>
                  <input
                    type="text"
                    className="inputSearch  form-control"
                    placeholder="Zipcode"
                    value={zipcodeAddress}
                    onChange={(e) => setzipcodeAddress(e.target.value)}
                  />
                </div>
                <div className='col-md-10 mb-3'>
                  <input
                    type="text"
                    className="inputSearch  form-control"
                    placeholder="STREET ADDRESS"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />

                </div>
              </div>
            </div>


            <button className="selectLocation"
              onClick={handleaddAddress}
            >
              Edit Address
            </button>
          </div>
        </Modal.Body>
      </Modal>





      <Modal
        show={selectAddress}
        onHide={() => setSelectAddress(!selectAddress)}
        size="md"
        centered
      >
        <Modal.Body>
          <div className="savedAddress mapPoup">
            <img className="mapFrame" src="./images/map-dummy.jpg" />
            <address>
              <h3>Block A</h3>
              <p>Block A, Sector 63, Noida, Uttar Pradesh 201301, India</p>
            </address>
            <div className="row">
              <div className="col-md-6">
                <input
                  className="form-control"
                  type="text"
                  placeholder="House/Flat Number *"
                />
              </div>
              <div className="col-md-6">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Landmark (Optional)"
                />
              </div>
              <div className="col-12 mt-4 mb-3">
                <button className="btnSub  btn">Save and Select slots</button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default HandymanCheckout;
