import React, { useEffect, useState } from "react";

import { FaStar } from "react-icons/fa";
import ServicesDetailCard from "./ServicesDetailCard";
import DecorativeLightsCard from "./DecorativeLightsCard";
import ServicesCategories from "./ServicesCategories";
import { useParams } from "react-router-dom";
import { API } from "../apiwrapper";
import apiURl from "../../Store/Action/api-url";
import { useLocation } from 'react-router-dom';

function HandymanServiceDetail() {
  const [servicesDetails, setServicesDetails] = useState([]);
  const [initialServiceSubCatId, setInitialServiceSubCatId] = useState([]);
  console.log(servicesDetails, "servicesDetailstitle");

  const location = useLocation();
    const servicedata = location.state?.ele;
    
    // console.log(servicedata,"servicedata")
  const { id } = useParams();
  useEffect(() => {
    const fetchServicesDettals = async () => {
      try {
        await API({
          url: `${apiURl.servicesubcategory}?ServiceCatId=${id}`,
          method: "GET",
        }).then((data) => {
          if (data?.status) {
            setServicesDetails(data?.result);
            setInitialServiceSubCatId(
              data?.result[1]?.ServiceSubCategoryId
            );
          } else {
            setServicesDetails([]);
          }
        });
      } catch (error) {
        throw error;
      }
    };
    fetchServicesDettals();
  }, [id]);

  return (
    <section className="detailSection">
      <div className="container">
        <div className="position-relative providerBanner">
          <img className="img-fluid" 
          // src="../images/detailImage.jpg"
          src={servicedata ? servicedata.Image : "../images/detailImage.jpg"}
           alt="" />

          <div className="eleTitle  position-absolute">
            <h4>{servicedata?.ServiceCategoryLanguage?.Title}</h4>
            <p>
              <FaStar />
              4.84 (3.4 M bookings)
            </p>
          </div>
        </div>
        <ServicesCategories
          servicesDetails={servicesDetails}
          initialServiceSubCatId={initialServiceSubCatId}
        />
        {/* <hr></hr> */}
        <div className="divide"></div>
        {/* <DecorativeLightsCard/> */}
      </div>
    </section>
  );
}

export default HandymanServiceDetail;
