import React, { useEffect, useState } from 'react'
import { FaCircleMinus, FaCirclePlus } from 'react-icons/fa6'
import { toast } from 'react-toastify';
import { API } from '../apiwrapper';
import apiURl from '../../Store/Action/api-url';
import { useDispatch, useSelector } from 'react-redux';
import { SetUserDettails } from '../../Store/reducer/UserReducer';
import { Link } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa'

function PersonalInfo() {
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [countryAddress, setcountryAddress] = useState('');
    const [addressState, setAddressState] = useState('');
    const [addressCity, setAddressCity] = useState('');
    const [address, setAddress] = useState('');
    const [coverImage, setCoverImage] = useState('');
    const [coverImageData, setCoverImageData] = useState('');
    const [addressZipcode, setAddressZipcode] = useState('');
    console.log(coverImageData,"coverImageDatacoverImageDatacoverImageData")
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const[zipCode,setZipcode]  =useState('');
    const [loading, setLoading] = useState(true);


    const user = useSelector((state) => state?.user);
    const dispatch = useDispatch();


    useEffect(() => {
        fetchCountries();
       fetchUserDetails();

    }, [user?.token]);



    const fetchCountries = async () => {
        try {
            await API({
                url: `${apiURl.countries}`,
                method: "GET",
            }).then((data) => {
                if (data?.status) {
                    setCountries(data?.data);
                } else {
                    setCountries([]);
                }
            });
        } catch (error) {
            // throw error;
            toast.error(error);
        }
    };

    const fetchAddressState = async (addressCountryId, type) => {
        try {
            await API({
                url: `${apiURl.stateLists}/${addressCountryId}`,
                method: "GET",
            }).then((data) => {
                if (data?.status) {
                    if (type === "address") {
                        setStates(data?.data);
                    } else {
                    }
                } else {
                    setStates([]);
                }
            });
        } catch (error) {
            // throw error;

            toast.error("error");
        }
    };

    const fetchAddressCity = async (addressStateId, type) => {
        try {
            await API({
                url: `${apiURl.cityLists}/${addressStateId}`,
                method: "GET",
            }).then((data) => {
                if (data?.status) {
                    if (type === "address") {
                        setCities(data?.data);
                    }
                    else {
                    }
                }
            });
        } catch (error) {
            // throw error;
            toast.error(error);
        }
    };


    useEffect(() => {
        if (countryAddress) {
            fetchAddressState(countryAddress, "address");
            fetchAddressCity(addressState, "address")
        }
    }, [countryAddress, addressState]);

    useEffect(() => {
        if (addressState) {
            fetchAddressCity(addressState, "address")
        }
    }, [addressState]);


    const handleImageSelect = (e) => {
        const selectedFile = e.target.files[0];
        // setCoverImage(selectedFile);
        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setCoverImage(selectedFile);
                setCoverImageData(event.target.result)
            };
            reader.readAsDataURL(selectedFile);
        }
    };



    const fetchUserDetails = async () => {
        try {
            setLoading(true);
            await API({
                url: `${apiURl.userDetais}`,
                method: "GET",
                headers: {
                    Authorization: `${user?.token}`,
                    Accept: "application/json",
                },
            }).then((data) => {
                if (data?.status=== true) {
                    dispatch(SetUserDettails(data?.data));

                    setFirstName(data?.data?.FirstName);
                    setLastName(data?.data?.LastName);
                    setEmail(data?.data?.Email);
                    setMobile(data?.data?.Mobile);

                    setcountryAddress(data?.data?.UserAddress?.CountryId);
                    setAddressState(data?.data?.UserAddress?.StateId);
                    setAddressCity(data?.data?.UserAddress?.CityId);
                    // setAddressZipcode(data?.data?.UserAddress?.ZipCode)
                    setZipcode(data?.data?.UserAddress?.ZipCode);
                    setAddress(data?.data?.UserAddress?.Address);

                    setCoverImageData(data?.data?.Image);


                }
            });
        } catch (error) {

            setLoading(false);
            toast.error(error);
        }
    };





    const handleSubmit = async (e) => {
        const payload = {
            "FirstName": firstName,
            "LastName": lastName,
            "Email": email,
            "CountryPhoneCode": "+91",
            "Mobile": mobile,
            "CountryId": countryAddress,
            "StateId": addressState,
            "CityId": addressCity,
            "ZipCode": zipCode,
            "Address": address,
        }

        console.log(payload,"userdatapayload");
        e.preventDefault();

        const formData = new FormData();

        formData.append('FirstName', firstName);
        formData.append('LastName', lastName);
        formData.append('Email', email);
        formData.append('CountryPhoneCode', '+91');
        formData.append('Mobile', mobile);
        formData.append('CountryId', countryAddress);
        formData.append('StateId', addressState);
        formData.append('CityId', addressCity);
        formData.append('ZipCode', zipCode);
        formData.append('Address', address);
        formData.append('Image', coverImage);

        try {
            await API({
                url: `${apiURl.updateuser}`,
                method: "PUT",
                headers: {
                    Authorization: `${user?.token}`
                },
                body: formData,
                formData: true,
            }).then((data) => {
                if (data?.status == true) {
                    toast.success(data?.message);
                    fetchUserDetails();
                } else {
                    toast.error(data?.error);
                }
            });
        } catch (error) {
            // setApiErrors({ message: error.message });
            // throw error;
            toast.error(error);
        }


    };


    return (
        <>

            <section className='py-5 my-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-xl-8'>
                            <h3 className='pageTitle mb-4'>Personal info</h3>

                            <div className='handyManForm'>
                                {/* Basic form */}
                                <div className='basicInfo d-flex mb-3'>
                                    <figure className='me-4'><img src='/images/basic-info.png' /></figure>
                                    <div className='vchForm'>
                                        <div className='basicTitle mb-lg-4 mb-3'>
                                            <h6>Basic Info</h6>
                                            <p>Enter the event title, description</p>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-5 mb-3'>
                                                <input type='text' className='form-control' placeholder='First Name'
                                                 value={firstName}
                                              onChange={(e) => setFirstName(e.target.value)} />
                                            </div>
                                            <div className='col-md-5 mb-3'>
                                                <input type='text' className='form-control' placeholder='Last Name' 
                                                 value={lastName}
                                                 onChange={(e) => setLastName(e.target.value)}
                                                />
                                            </div>
                                            <div className='col-md-5 mb-3'>
                                                <input type='text' className='form-control' placeholder='Email address'
                                                 value={email}
                                                 onChange={(e) => setEmail(e.target.value)} />
                                            </div>
                                            <div className='col-md-5 mb-3'>
                                                <input type='text' className='form-control' placeholder='Phone number'
                                                 value={mobile}
                                                 onChange={(e) => setMobile(e.target.value)} />
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>

                                {/* Handyman address area */}
                                <div className='basicInfo d-flex mb-3'>
                                    <figure className='me-4'><img src='/images/address.png' /></figure>
                                    <div className='vchForm'>
                                        <div className='basicTitle mb-lg-4 mb-3'>
                                            <h6>Address</h6>
                                            <p>Enter the address of User</p>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-5 mb-3'>
                                                <select className='form-control' onChange={(e) => setcountryAddress(parseInt(e.target.value))} value={countryAddress}>
                                                    <option>Select Country</option>
                                                    {countries?.map((country) =>
                                                    (
                                                        <option key={country?.id} value={country?.Id}> {country?.Name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className='col-md-5 mb-3'>
                                                <select className='form-control' onChange={(e) => setAddressState(parseInt(e.target.value))} value={addressState}>
                                                    <option>Select State</option>
                                                    {states?.map((st) => (
                                                        <option key={st?.Id} value={st?.Id}>{st?.Name}</option>
                                                    ))}

                                                </select>
                                            </div>
            
                                            <div className='col-md-5 mb-3'>
                                                <select className='form-control' onChange={(e) => setAddressCity(parseInt(e.target.value))} value={addressCity}>
                                                    <option>City</option>
                                                    {cities.map((ci) => (
                                                        <option key={ci?.Id} value={ci?.Id}>
                                                            {ci?.Name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className='col-md-5 mb-3'>
                                                <input type='text' className='form-control' placeholder='Zipcode'
                                                 value={zipCode}
                                              onChange={(e) => setZipcode(e.target.value)} />
                                            </div>
                                            <div className='col-md-10 mb-3'>
                                                <textarea className='form-control' placeholder='Address'
                                                 onChange={(e) => setAddress(e.target.value)}
                                                value={address} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="basicInfo d-flex mb-3">
                                    <figure className="me-4"><img src="/images/upload_image_icon.svg" alt="" /></figure>
                                    <div className="vchForm">
                                        <div className="basicTitle mb-lg-4 mb-3">
                                            <h6>Upload Cover Image <span>*</span></h6>
                                            <p>Upload discount offer images</p>
                                        </div>
                                        <div className="row pb-4">
                                            <div className="col-md-8 form-group">
                                                <div className="file-upload uploadImage">
                                                    <label htmlFor="upload" className="file-upload__label">
                                                        <div className="fileTitle d-flex align-items-center">
                                                            <figure><img src="/images/uploadIcon.svg" alt="" /></figure>
                                                            <div>
                                                                Click to Upload or drag and drop
                                                                <p>Svg, PNG, JPG or GIF (1000x600 max. 20mb)</p>
                                                            </div>
                                                        </div>
                                                    </label>
                                                    <input id="upload" className="file-upload__input" type="file" name="file-upload" onChange={handleImageSelect} />
                                                </div>
                                                {(!coverImage && coverImageData !== '') ? (
                                                    <ul className="upload_Image">
                                                        <li>
                                                            <img src={coverImageData} alt="" height={250} width={250} />
                                                            <button onClick={() => setCoverImage(null)}><FaTimes /></button>
                                                        </li>
                                                    </ul>
                                                ) : (coverImage) && (<ul className="upload_Image">
                                                    <li>
                                                        <img src={URL.createObjectURL(coverImage)} alt="" height={250} width={250} />
                                                        <button onClick={() => setCoverImage(null)}><FaTimes /></button>
                                                    </li>
                                                </ul>)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12'>
                                    <div className='nextPreviousButton subBtn mt-5'>
                                        <button type="btn" className="can d-flex justify-content-between align-items-center"><img src='/images/rightArrow.svg' />Cancel</button>
                                        <button type="btn" className="sub ms-auto d-flex justify-content-between align-items-center" onClick={handleSubmit} >Save Info <img src='/images/arrow-right.svg' /></button>
                                    </div>
                                </div>
                            </div>



                        </div>


                        {/* <div className='col-xl-4'>
                            <h3 className='pageTitle mb-4'>Other links</h3>
                            <Link className="can d-flex justify-content-between align-items-center" to ="/yourReservationsOrder">Your ReservationsOrder</Link>

                        </div> */}
                    </div>
                </div>
            </section>

        </>
    )
}

export default PersonalInfo