import React, { useState } from "react";
import { API } from "../apiwrapper";
import apiURl from "../../Store/Action/api-url";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { SetpopupReducerData } from "../../Store/reducer/PopupReducer";
import { toast } from "react-toastify";
import { SetUserData } from "../../Store/reducer/user";
import { SetUserDettails, login } from "../../Store/reducer/UserReducer";

const OtpModal = () => {
  const [isLoadingResendotp, setIsLoadingResendotp] = useState(false);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [show, setShow] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const[Token,setToken]=useState();
  const user = useSelector((state) => state?.user);
  const [otpError, setOtpError] = useState('');
  console.log(user,"token");

  const email = useSelector((state) => state?.PopupReducer?.modal?.Email);

  const dispatch = useDispatch();


  const handleOtpChange = (element, index) => {
    if (isNaN(element.value)) return false;
    const newOtp = [...otp];
    console.log(newOtp, "newOtpnewOtp");
    newOtp[index] = element.value;
    setOtp(newOtp);

    if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }
  };
  const handleClosepopup = () => {
    dispatch(SetpopupReducerData({ modalType: "", showModal: false }));
  };

  const closeModal = () => {
    setShow(false);
  };
  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const otpValue = otp.join("");
    if (otpValue.length < 6) {
      setOtpError('Please fill all OTP fields');
      setIsLoading(false);
      return; 
    }
    else
    {
    try {
      await API({
        url: `${apiURl.otpverify}`,
        method: "POST",
        body: {
          Otp: otpValue,
          Email: email,
          CountryPhoneCode: "",
          Mobile: "",
        },
        formData: false,
      }).then((data) => {
        if (data?.status == true) {
          toast.success(data?.message);
          dispatch(login({ data }));
          localStorage.setItem("token", data?.result);
          setOtp("");
          setOtp(new Array(6).fill(""));
          handleClosepopup();
            try {
                setLoading(true);
                 API({
                    url: `${apiURl.userDetais}`,
                    method: "GET",
                    headers: {
                        Authorization: `${data?.result}`,
                        Accept: "application/json",
                    },
                }).then((data) => {
                    if (data?.status=== true) {
                         dispatch(SetUserDettails(data?.data));
                    }
                });
            } catch (error) {
        
                setLoading(false);
                toast.error(error);
            }
        
        } else {
          // setApiErrors({ message: data?.message });
          toast.error(data?.error);
        }
      });
    } catch (error) {
      // setApiErrors({ message: error.message });
      throw error;
    } finally {
      setIsLoading(false);
    }
    setShow(false);
  }
  };

  const resend = async (e) => {
    e.preventDefault();
    setIsLoadingResendotp(true);
   
    try {
      await API({
        url: `${apiURl.sentotp}`,
        method: "POST",
        body: { Email: email },
        formData: false,
      }).then((data) => {
        if (data?.status == true) {
          toast.success(data?.message);
          try {
            setLoading(true);
             API({
                url: `${apiURl.userDetais}`,
                method: "GET",
                headers: {
                    Authorization: `${data?.result}`,
                    Accept: "application/json",
                },
            }).then((data) => {
                if (data?.status=== true) {
                     dispatch(SetUserDettails(data?.data));
                }
            });
        } catch (error) {
    
            setLoading(false);
            toast.error(error);
        }

        } else {
          // setApiErrors({ message: data?.message });
          toast.error(data?.error);
        }
      });
    } catch (error) {
      // setApiErrors({ message: error.message });
      throw error;
    } finally {
      setIsLoadingResendotp(false);
    }
  
  };



  return (
    <>
      <Modal show={show} className="h">
        <Modal.Body className="pt-4 pb-5">
          {/* <div className="otpModal"> */}
            {/* <div className="otpModalContent"> */}
              <span className="close" onClick={handleClosepopup}>
                &times;
              </span>
              <h2>Please Verify</h2>
              <p>
                Please enter 6 digit otp sent on your email address or mobile number{" "}
                <a className="link">{email}</a>
              </p>
              <div className="d-flex" id="otp">
                {otp.map((data, index) => (
                  <input
                    key={index}
                    type="text"
                    className="otpInput"
                    maxLength="1"
                    value={data}
                    onChange={(e) => handleOtpChange(e.target, index)}
                  />
                ))}
              </div>
              <div className="text-center mt-3">
              {otpError && <p className="error-message" style={{color:"red"}}>{otpError}</p>}
                <Button variant="primary" onClick={handleOtpSubmit}>
                  Verify OTP
                </Button>
                <p className="link">
                  <a className="pt-2 d-inline-block" onClick={resend}>
                    {isLoadingResendotp ? "Resend Otp..." : "Resend Otp"}
                  </a>
                </p>
              </div>
            {/* </div> */}
          {/* </div> */}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default OtpModal