import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./Components/Layout/Layout";
import Homepage from "./Components/Home/Homepage";
import HandymanServiceDetail from "./Components/Home/HandymanServiceDetail";
import HandymanCheckout from "./Components/Home/HandymanCheckout";
import Login from "./Components/Auth/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import OpenModal from "./Components/Modal/OpenModal";
import JoinHandyman from "./Components/Home/JoinHandyman";
import YourReservationsOrder from "./Components/Home/YourReservationsOrder";
import Account from "./Components/Home/Account";
import PersonalInfo from "./Components/Home/PersonalInfo";


function App() {
  const [showPopup, setShowPopup] = useState(false);
  // const { PopupReducer } = useSelector((state) => state);
  // const { showModal = false } = PopupReducer?.modal;

  const showModal = useSelector((state) => state?.PopupReducer?.modal?.showModal);


  useEffect(() => {
    setShowPopup(showModal);
    return () => {
      setShowPopup(false);
    };
  }, [showModal]);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {showPopup && <OpenModal />}

      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Homepage />} />

            <Route
              path="/handymanServicesDetail/:id"
              element={<HandymanServiceDetail />}
            />
            <Route path="/login" element={<Login />} />
            <Route path="/handymanCheckout" element={<HandymanCheckout />} />
            <Route path="/joinHandyman" element={<JoinHandyman />} />
            <Route path="/yourReservationsOrder" element={<YourReservationsOrder />} />
            <Route path="/accountPage" element={<Account />} />
            <Route path="/personalInfo" element={<PersonalInfo />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
