import { Link } from "react-router-dom";
import apiURl from "../../Store/Action/api-url";
import { useSelector } from "react-redux";

import Modal from "react-bootstrap/Modal";

import React, { useEffect, useState } from "react";
import { API } from "../apiwrapper";
import { Button, Dropdown, NavDropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { SetUserData } from "../../Store/reducer/user";
import { useDispatch } from "react-redux";
import { SetpopupReducerData } from "../../Store/reducer/PopupReducer";
import { FaMapMarkerAlt, FaSearch } from "react-icons/fa";
import { BsGlobe } from "react-icons/bs";
import JoinHandyman from "../Home/JoinHandyman";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n/config";
import { SetUserDettails, logout } from "../../Store/reducer/UserReducer";
import { resetCart } from "../../Store/reducer/Cart";
import { BiSearchAlt2 } from "react-icons/bi";

function Header() {
  // const { user = {} } = useSelector((state) => state);
  const user = useSelector((state) => state?.user);
  const cartvalue = useSelector((state) => state?.cart?.data);
  const username = useSelector((state) => state?.user?.UserDettails);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setsearchQuery] = useState();
  const [serachdata, setsearchdata] = useState();
  const dispatch = useDispatch();


  const { t } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);

    localStorage.setItem("langs", language);
    window.location.reload();

  };
  const navigate = useNavigate();

  const handleShowLogin = () => {
    if (!user?.isAuthenticated && user?.token === "") {
      dispatch(
        SetpopupReducerData({
          modalType: "LOGIN",
          showModal: true,
        })
      );
    }
  };

  const handleLogout = () => {
    dispatch(logout());
    dispatch(resetCart());
    dispatch(SetUserDettails());
    localStorage.setItem("token", "");
    navigate('/');
    // window.location.reload();
  }

  const sendcartpage = () => {
    navigate('/handymanCheckout')
  }

  const senduserupdate = () => {
    navigate('/accountPage');
  }

  const serviceSearch = async (searchQuery) => {
    if (searchQuery) {
      try {
        setLoading(true);
        await API({
          url: `${apiURl.servicesearch}?search=${searchQuery}`,
          method: "GET",
          headers: {
            Authorization: `${user?.token}`,
            Accept: "application/json",
          },
        }).then((data) => {
          if (data?.status === true) {
            setsearchdata(data?.result);
          }
        });
      } catch (error) {

        setLoading(false);
        toast.error(error);
      }
    }
    else {
      setsearchdata();
    }

  }

  const datapass = (ele) => {
    navigate(`/handymanServicesDetail/${ele?.Id}`, { state: { ele } });
    setsearchdata();
  }

  return (
    <>
      <header>
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-auto">
              <Link to="/" className="topLogo">
                <img src="./images/logo.png" className="img-fluid" alt="Logo" />
              </Link>{" "}
            </div>
            <div className="col-xl-5 d-flex align-items-center">
              <div className="twoMultiSearch">
                <div className="locSearch" >
                  <FaSearch className="customsearch" />
                  <input type="text"
                    className="customsearchplaceorder"
                    placeholder="Search for your services"
                    value={searchQuery}
                    onChange={(e) => {
                      setsearchQuery(e.target.value)
                      serviceSearch(e.target.value);
                    }}
                  />

                  <ul>
                    {serachdata && serachdata.map((ele, index) => (
                      <li onClick={() => datapass(ele)}>
                        {ele?.ServiceCategoryLanguage?.Title}
                      </li>
                    ))}
                  </ul>


                </div>

              </div>
            </div>


            <div className="col-xl-5 d-flex align-items-center">
              {/* <div className="stellarnav">
                <ul>
                  <Link to="/" className="active">
                    <li>Home</li>
                  </Link>
                  <li><a href="">About Us</a></li>
                  <li><a href="">Rent a House</a></li>
                  <li><a href="">Join as a Handyman</a></li>
                  <li><a href="">Analytics Map</a></li>
                  <li><a href="">Contact Us</a></li>
                </ul>
              </div> */}
              <div className="loginWith">
                <Link className="joinHandyman" to="/joinHandyman">{t(`Join Handyman`)}</Link>
                <Dropdown className="languageInfo">
                  <Dropdown.Toggle variant="" id="dropdown-basic">
                    <BsGlobe />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item ><button onClick={() => changeLanguage("en")}>English </button></Dropdown.Item>
                    <Dropdown.Item ><button onClick={() => changeLanguage("es")}>Spanish</button></Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                {(user?.isAuthenticated && user?.token != "") ? (
                  <>
                    <button
                      className="signIn"
                      onClick={handleLogout}
                    >
                      {t(`Logout`)}
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="signIn"
                      data-toggle="modal"
                      data-target="/login"
                      onClick={handleShowLogin}
                    >
                      {t(`login`)}
                    </button>
                  </>
                )}
              </div>
              {
                (user?.isAuthenticated && user?.token != "")
                  ? (
                    <> <ul className="loginDetail d-flex align-items-center mb-0 ps-3">
                      <li className="me-lg-4">
                        <div className="cart" onClick={sendcartpage}>
                          <figure>
                            <img src="./images/cartIcon.svg" alt="" />
                            {cartvalue && cartvalue?.length > 0 ?
                              (<figcaption >
                                {cartvalue && cartvalue?.length}
                              </figcaption>
                              ) :
                              ("")
                            }
                          </figure>
                        </div>
                      </li>

                      <ul className="loginDetail d-flex align-items-center mb-0 ps-3">

                        <li onClick={senduserupdate}>
                          <div className="useLogin d-flex align-items-center">
                            {username && username?.FirstName ? <figure><img src={username.Image} alt="" /></figure> : <figure><img src="./images/cat-10.jpg" alt="" /></figure>}

                            {username && username?.FirstName ? <p>{username.FirstName}</p> : <p>User</p>}
                          </div>
                        </li>
                      </ul>

                    </ul></>
                  ) :
                  (
                    <>

                    </>
                  )
              }
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
