import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { API } from "../apiwrapper";
import apiURl from "../../Store/Action/api-url";
import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from 'react-router-dom';
const Homepage = () => {
  const [service, setService] = useState([]);
  console.log(service, "service")
  // const { user = {} } = useSelector((state) => state);
  const user = useSelector((state) => state?.user);
  let token = user?.token;
  const location = useLocation();
  const navigate = useNavigate();
  // console.log(token, "token");
  // const decoded = jwtDecode(token);
  // console.log(decoded, "decoded data");

  const fetchServices = async () => {
    try {
      await API({
        url: `${apiURl.servicecategory}`,
        method: "GET",
      }).then((data) => {
        // console.log("data", data?.result);
        if (data?.status) {
          setService(data?.result);
        } else {
          setService([]);
        }
      });
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    fetchServices();
  }, []);

  const datapass=(ele)=>{
     navigate(`/handymanServicesDetail/${ele?.Id}`, { state: { ele } });
  }
  return (
    <>
      <section className="handymanBanner">
        <div className="bannerBox">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <span>Handyman Services</span>
                <h1>
                  Complete Repairs For <br className="d-none d-xl-block" />
                  Your Home
                </h1>
                <p>
                  From Minor Fixes to Major Renovations -{" "}
                  <br className="d-none d-xl-block" />
                  We Do It All for Your Home
                </p>
              </div>
            </div>
          </div>
        </div>
        <img src="./images/handymanServices.jpg" />
      </section>

      <section className="ourServices">
        <div className="container">
          <h2>Our Services</h2>
          <div className="serviceBox" >
            {service?.map((ele) => (

              <div key={ele?.Id} className="servItem  position-relative"
               onClick={()=>datapass(ele)}
               >
                <img className="img-fluid" src={ele?.Image} />
                <div className="contEnt">
                  <h3>{ele?.ServiceCategoryLanguage?.Title}</h3>
                  <p>{ele?.ServiceCategoryLanguage?.Description}</p>
                </div>
                <Link
                  className="stretched-link"
                   to={`/handymanServicesDetail/${ele?.Id}`}
                 
                    ></Link>
              </div>

            ))}
          </div>
        </div>
      </section>

      <section className="propFind">
        <div>
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-6">
                <h1 className="title">
                  Hassle Free <br className="d-none d-xl-block" />
                  Maintenance <br className="d-none d-xl-block" />
                  on one Click
                </h1>
                <p>
                  Once property is added user only need to log a issue, And we
                  will take <br className="d-none d-xl-block" />
                  care of rest of things.
                </p>
                <button className="btnBlue">Let's Start</button>
              </div>
              <div className="col-lg-5">
                <img className="img-fluid" src="./images/hassleFree2.png" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Homepage;
