import React from 'react'
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

function Account() {
    const user = useSelector((state) => state?.user);
    const navigate = useNavigate();

  return (
    <>
    
        <section className='py-5 my-5'>
            <div className='container'>
                <h3 className='pageTitle'>Account</h3>
                <p className='subLink mb-4'>{user?.UserDettails?.FirstName? user?.UserDettails?.FirstName :"User"} {user?.UserDettails?.LastName? user?.UserDettails?.LastName :""}, <a href='#'>{user&&user?.UserDettails?.Email}</a>·<Link to="/personalInfo"> Go to profile</Link></p>
                <div className='row' >
                    <div className='col-xl-4'>
                        <div className='accountBox'>
                            <img src='/images/account-edit.png'/>
                       <Link to="/personalInfo"> <h3>Personal Info</h3></Link>    
                            <p>Provide personal details and how we can reach you</p>
                        </div>
                    </div>
                    <div className='col-xl-4'>
                        <div className='accountBox'>
                            <img src='/images/global-preferences.png'/>
                           <Link to="/yourReservationsOrder"><h3>Your Reservations Order</h3></Link> 
                            <p>Provide personal details and how we can reach you</p>
                        </div>
                    </div>
                    {/* <div className='col-xl-4'>
                        <div className='accountBox'>
                            <img src='/images/your-requests.png'/>
                            <h3>Personal Info</h3>
                            <p>Provide personal details and how we can reach you</p>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
    
    </>
  )
}

export default Account