import React from "react";

import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa6";

function Footer() {
  return (
    <>
      <footer>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-12">
              <h3 className="text-center mt-5 mb-3">Available on</h3>
              <ul className="store">
                <li>
    
                  <a href="#">
                    <img src="./images/google-play.png" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src="./images/app-store.png" />
                  </a>
                </li>
              </ul>
              <hr className="my-5" />
            </div>
            <div className="col-xl-4 text-center text-md-start">
              <h3>About Decode Home</h3>
              <p>
                Required honoured trifling eat pleasure man relation. Assurance
                yet bed was improving furniture man. Distrusts delighted Excuse
                few the remain highly feebly add people manner say. It high at
                my mind by roof.
              </p>
              <ul className="socalMedia">
                <li>
                  <a href="#">
                    <FaFacebookF />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <FaTwitter />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <FaInstagram />
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-xl-7 text-center text-md-start">
              <hr className="d-xl-none" />
              <div className="row">
                <div className="col-md-4">
                  <h3>Services</h3>
                  <ul className="fotLink">
                    <li>
                      <a href="#">Hassle Free Maintenance</a>
                    </li>
                    <li>
                      <a href="#">Rent a House</a>
                    </li>
                    <li>
                      <a href="#">Join as Handyman</a>
                    </li>
                    <li>
                      <a href="#">Analytics Map</a>
                    </li>
                  </ul>
                </div>

                <div className="col-md-4">
                  <h3>Company</h3>
                  <ul className="fotLink">
                    <li>
                      <a href="#">About Us</a>
                    </li>
                    <li>
                      <a href="#">Contact Us</a>
                    </li>
                    <li>
                      <a href="#">Career</a>
                    </li>
                    <li>
                      <a href="#">Terms</a>
                    </li>
                    <li>
                      <a href="#">Team Members</a>
                    </li>
                  </ul>
                </div>

                <div className="col-md-4">
                  <h3>Contact Us</h3>
                  <ul className="fotAdres">
                    <li>
                      <b>Address</b>
                      <p>California, TX 70240</p>
                    </li>
                    <li>
                      <b>Email</b>
                      <p>
                        <a href="mailto:hello@decode-home.com">
                          hello@decode-home.com
                        </a>
                      </p>
                    </li>
                    <li>
                      <b>Contact</b>
                      <p>+44-20-7328-4499</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-xl-12">
              <hr className="my-4" />
              <p className="copyRight">© Copyright 2021 decode-home</p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
