import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    isAuthenticated: false,
    token: "",
    UserDettails:"" 
  },
  reducers: {
    login: (state, action) => {
      state.isAuthenticated = true;
      state.token = action.payload.data.result;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.token = "";
    },
    UserDettails: (state, { payload }) => {
      state.UserDettails = payload.data;
    },
    },
});

export const { login, logout,UserDettails } = userSlice.actions;
export default userSlice.reducer;

export const SetUserDettails = (data={}) => async (dispatch) => {
  if (!data) throw Error("missing Data parameter");
  dispatch(UserDettails({ data }));
};


