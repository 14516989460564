import React from "react";
import { useSelector } from "react-redux";
import Login from "../Auth/Login";
import OtpModal from "./OtpModal";

function OpenModal() {
  // const { PopupReducer } = useSelector((state) => state);
  // const { modalType = "", showModal = false } = PopupReducer?.modal || {};

  const { modalType, showModal } = useSelector((state) => state?.PopupReducer?.modal);

  return <>

    {showModal && <>
      {modalType === "LOGIN" && <Login />}
      {modalType === "OTP" && <OtpModal />}



    </>
    }
  </>;
}

export default OpenModal;
