import React, { useEffect, useState } from "react";
import { FaStar, FaRupeeSign } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { addItem, setUserCartData } from "../../Store/reducer/Cart";
import { API } from "../apiwrapper";
import apiURl from "../../Store/Action/api-url";
import { SetpopupReducerData } from "../../Store/reducer/PopupReducer";
import { toast } from "react-toastify";
function ServicesDetailCard({ servicesSubDetails, serviceCategoryId, serviceSubCatId }) {
  //  console.log(servicesSubDetails,"ServicedetailServicedetail");
  const [allServices, setAllServices] = useState([]);
  const user = useSelector((state) => state?.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cartData, setCartData] = useState([]);

  useEffect(() => {
    const fetchAllServicesDettals = async () => {
      try {
        await API({
          url: `${apiURl.serviceDetails}?ServiceCatId=${serviceCategoryId}&ServiceSubCatId=${serviceSubCatId}`,
          method: "GET",
        }).then((data) => {
          if (data?.status) {
            setAllServices(data?.result);
          } else {
            setAllServices([]);
          }
        });
      } catch (error) {
        throw error;
      }
    };
    fetchAllServicesDettals();
  }, [serviceSubCatId]);

  const fetchAddToCart = async (ele) => {
    let formData = {
      "ServiceDetailsId": ele?.ServiceDetailLanguage?.ServiceDetailId,
      "Price": ele?.ServiceCost,
      "Tax": 10,
      "TotalPrice": parseInt(ele?.ServiceCost) + 10,
      "ServiceQuantity": 1
    }

    try {
      await API({
        url: `${apiURl.addtocart}`,
        method: "POST",
        headers: {
          Authorization: `${user?.token}`
        },
        body: formData,
      }).then((data) => {
        if (data?.status) {
          if (data?.result) {
            console.log(data?.result, "56544444");

            setCartData(data?.result);
            //  dispatch(addItem(data?.result));
            toast.success(data?.message);
          } else {
            setAllServices([]);
            toast.error(data?.error);
          }
        } else {
          setAllServices([]);
          toast.error(data?.message);
        }
      });
    } catch (error) {
      throw error;
    }
  };


  function handleAddToCart(ele) {

    console.log(ele,"eleelelelel")

    if (!user?.isAuthenticated && user?.token === "") {
      dispatch(
        SetpopupReducerData({
          modalType: "LOGIN",
          showModal: true,
        })
      );
    } else {
      // dispatch(addItem(ele));
      fetchAddToCart(ele);
      navigate("/handymanCheckout");
    }


  }
  return (
    <>
      <div className="quickMain">
        <h2>Quick booking</h2>
        <div className="quickDetail">
          {allServices.map((ele, index) => (

            <div key={index} className="d-flex justify-content-between">
              <div className="insDetail">
                <h3>{ele?.ServiceDetailLanguage?.Title}</h3>
                <div className="rating">
                  <i>
                    <FaStar />
                  </i>{" "}
                  4.77 (2.5K reviews)
                </div>
                <div className="priceTime">
                  <FaRupeeSign />
                  {ele?.ServiceCost} <span>{ele?.ServiceTime} mins</span>
                </div>
                <div className="insPoint">
                  <ul>
                    <li>{ele?.ServiceDetailLanguage?.Description}</li>
                  </ul>
                </div>
                {/* <Link to="/handymanCheckout"> */}
                {/* <Link to=""> */}
                <button className="addBtn" onClick={() => handleAddToCart(ele)}>Add</button>
                {/* </Link> */}
              </div>
              <div className="gsImage">
                <img src={ele?.Image} alt="" />{" "}
              </div>
            </div>

          ))}
        </div>
      </div>
    </>
  );
}

export default ServicesDetailCard;
