// // cartSlice.js
// import { createSlice } from '@reduxjs/toolkit';

// const cartSlice = createSlice({
//     name: 'cart',
//     initialState: [],
//         // data: {},
//     // },
//     reducers: {
//         addItem: (state, action) => {
//             state.push(action.payload);
//         },
//         removeItem: (state, action) => {
//             const itemToRemove = action.payload;
//             return state.filter(item => item !== itemToRemove);
//         },
//         fetched: (state, { payload }) => {
//             state.cart = [...state.cart, payload.data];
//         },
//         resetCart: () => {
//             return [];
//         },
//         fetched: (state, { payload }) => {
//             state.data = payload.data;
//           },
//     },
// });

// export const { addItem, removeItem, fetched, resetCart } = cartSlice.actions;
// export default cartSlice.reducer;

// export const setUserCartData = (data) => async (dispatch) => {
//     if (!data) throw Error("missing Data parameter");
//     dispatch(fetched({ data }));
// }


import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        items: [],
        data: {}
    },
    reducers: {
        addItem: (state, action) => {
            state.items.push(action.payload);
        },
        removeItem: (state, action) => {
            state.items = state.items.filter(item => item !== action.payload);
        },
        updateCartData: (state, { payload }) => {
            state.data = payload.data;
        },
        resetCart: (state) => {
            state.items = [];
            state.data = {};
        },
    },
});

export const { addItem, removeItem, updateCartData, resetCart } = cartSlice.actions;
export default cartSlice.reducer;

export const setUserCartData = (data) => async (dispatch) => {
    if (!data) throw Error("missing Data parameter");
    dispatch(updateCartData({ data }));
}
