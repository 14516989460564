import React, { useEffect, useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import { FaCircleMinus, FaCirclePlus, FaPlus } from 'react-icons/fa6'
import apiURl from "../../Store/Action/api-url";
import { API } from '../apiwrapper';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { SetpopupReducerData } from '../../Store/reducer/PopupReducer';
import { useNavigate } from 'react-router-dom';
import { SetUserDettails } from '../../Store/reducer/UserReducer';

function JoinHandyman() {

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [zipcodes, setZipcodes] = useState([]);

    const [workStates, setWorkStates] = useState([]);
    const [workCities, setWorkCities] = useState([]);


    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [countryPhoneCode, setCountryPhoneCode] = useState('');
    const [mobile, setMobile] = useState('');
    const[zipCode,setZipcode] =useState('')

    const [addressCountry, setAddressCountry] = useState('');
    const [addressState, setAddressState] = useState('');
    const [addressCity, setAddressCity] = useState('');
    const [addressZipcode, setAddressZipcode] = useState('');
    const [address, setAddress] = useState('');
    const [addressType, setAddressType] = useState('2');


    const [workCountry, setWorkCountry] = useState('');
    const [workState, setWorkState] = useState('');
    const [workCity, setWorkCity] = useState('');
    const [workingAddressType, setWorkingAddressType] = useState('3');

    const [servedArea, setServedArea] = useState("8");  // Html integration is pending 
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [details, setDetails] = useState([]);

    const [specializations, setSpecializations] = useState([{ ServiceCatId: '', ServiceDetailId: '', ServiceSubCatId: '', VisitingCharge: '', HourlyCharge: '', categories: categories, subCategories: [], details: [] }]);

    const [coverImage, setCoverImage] = useState('');
    const [coverImageData, setCoverImageData] = useState('');
    const [userDetais, setUserDetails] = useState('');

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state?.user);

    useEffect(() => {
        if (!user?.isAuthenticated && user?.token === "") {
            dispatch(
                SetpopupReducerData({
                    modalType: "LOGIN",
                    showModal: true,
                })
            );
        }
        fetchCountries();
        fetchCategories();
        fetchUserDetails();
    }, [user?.token]);

    useEffect(() => {
        if (addressCountry) {
            fetchAddressState(addressCountry, "address");
        }
    }, [addressCountry]);

    useEffect(() => {
        if (workCountry) {
            fetchAddressState(workCountry, "work");
        }
    }, [workCountry]);

    useEffect(() => {
        if (addressState) {
            fetchAddressCity(addressState, "address");
        }
    }, [addressState]);

    useEffect(() => {
        if (workState) {
            fetchAddressCity(workState, "work");
        }
    }, [workState]);

    const fetchSubCategoriesAsync = async (ServiceCatId, index) => {
        // Fetch subcategories here and return the result
        // setLoading(true);
        const subCategoriesResult = await API({
            url: `${apiURl.serviceSubCategories}?ServiceCatId=${ServiceCatId}`,
            method: "GET",
        });
        // setLoading(false);
        return (subCategoriesResult?.result) ? subCategoriesResult?.result : [];
    };
    const fetchCategoriesAsync = async (ServiceCatId, index) => {
        // Fetch categories here and return the result
        // setLoading(true);
        const categoriesResult = await API({
            url: `${apiURl.serviceCategories}?ServiceCatId=${ServiceCatId}`,
            method: "GET",
        });
        // setLoading(false);
        return (categoriesResult?.result) ? categoriesResult?.result : [];
    };
    const fetchDetailsAsync = async (ServiceCategoryId, ServiceSubCategoryId, index) => {
        // Fetch derails here and return the result
        // setLoading(true);
        const detailsResult = await API({
            url: `${apiURl.serviceDetails}?ServiceCatId=${ServiceCategoryId}&ServiceSubCatId=${ServiceSubCategoryId}`,
            method: "GET",
        });
        // setLoading(false);
        return (detailsResult?.result) ? detailsResult?.result : [];
    };

    const fetchUserDetails = async () => {
        try {
            setLoading(true);
            await API({
                url: `${apiURl.userDetais}`,
                method: "GET",
                headers: {
                    Authorization: `${user?.token}`,
                    Accept: "application/json",
                },
            }).then((data) => {
                if (data?.status) {
                 dispatch(SetUserDettails(data?.data));
                     setUserDetails(data?.data);
                    setFirstName(data?.data.FirstName);
                    setLastName(data?.data.LastName);
                    setEmail(data?.data.Email);
                    setCountryPhoneCode(data?.data.CountryPhoneCode);
                    setMobile(data?.data.Mobile);

                    setAddressCountry(data?.data?.UserAddress?.CountryId);
                    setAddressState(data?.data?.UserAddress?.StateId);
                    setAddressCity(data?.data?.UserAddress?.CityId);
                    // setAddressZipcode(data.data.UserAddress.ZipCode);
                    setZipcode(data?.data?.UserAddress?.ZipCode)
                    setAddress(data?.data?.UserAddress?.Address);
                    setAddressType(data?.data?.UserAddress?.AddressType);

                    setCoverImageData(data?.data?.Image);

                    setWorkCountry(data?.data?.WorkingAddress?.WorkingCountryId);
                    setWorkState(data?.data?.WorkingAddress?.WorkingStateId);
                    setWorkCity(data?.data?.WorkingAddress?.WorkingCityId);
                    setWorkingAddressType(data?.data?.WorkingAddress?.WorkingAddressType);

                    const promises = data.data.HandymanServices.map(async (service, index) => {
                        const ser = {
                            ServiceCatId: service?.ServiceCatId,
                            ServiceDetailId: service?.ServiceDetailId,
                            ServiceSubCatId: service?.ServiceSubCatId,
                            VisitingCharge: service?.VisitingCharge,
                            HourlyCharge: service?.HourlyCharge,
                            categories: await fetchCategoriesAsync(index),
                            subCategories: (service.ServiceCatId) ? await fetchSubCategoriesAsync(service.ServiceCatId, index) : [],
                            details: (service.ServiceCatId && service.ServiceSubCatId) ? await fetchDetailsAsync(service.ServiceCatId, service.ServiceSubCatId, index) : [],
                        };

                        return { service: ser };
                    });

                    // Use Promise.all to wait for all promises to resolve
                    Promise.all(promises)
                        .then((results) => {
                            const services = results.map((result) => result.service);
                            console.log(services, "services");
                            setSpecializations(services);
                            setLoading(false);
                        })
                        .catch((error) => {
                            console.error("Error fetching data:", error);
                        });

                }
            });
        } catch (error) {
            // throw error;

            console.log("loading");
            setLoading(false);
            toast.error(error);
        }
    };

    const fetchCountries = async () => {
        try {
            await API({
                url: `${apiURl.countries}`,
                method: "GET",
            }).then((data) => {
                if (data?.status) {
                    setCountries(data?.data);
                } else {
                    setCountries([]);
                }
            });
        } catch (error) {
            // throw error;
            toast.error(error);
        }
    };

    const fetchAddressState = async (addressCountryId, type) => {
        try {
            await API({
                url: `${apiURl.stateLists}/${addressCountryId}`,
                method: "GET",
            }).then((data) => {
                if (data?.status) {
                    if (type === "address") {
                        setStates(data?.data);
                        setCities([]);
                    } else {
                        setWorkStates(data?.data);
                        setWorkCities([]);
                    }
                } else {
                    setStates([]);
                }
            });
        } catch (error) {
            // throw error;

            toast.error("error");
        }
    };

    const fetchAddressCity = async (addressStateId, type) => {
        try {
            await API({
                url: `${apiURl.cityLists}/${addressStateId}`,
                method: "GET",
            }).then((data) => {
                if (data?.status) {
                    if (type === "address") {
                        setCities(data?.data);
                    } else {
                        setWorkCities(data?.data);
                    }
                } else {
                    setCities([]);
                }
            });
        } catch (error) {
            // throw error;
            toast.error(error);
        }
    };


    const handleAddField = () => {
        setSpecializations([...specializations, { ServiceCatId: '', ServiceDetailId: '', ServiceSubCatId: '', VisitingCharge: '', HourlyCharge: '', categories: categories, subCategories: [], details: [] }]);
    };

    const handleRemoveField = (index) => {
        const updatedSpecializations = specializations.filter((_, i) => i !== index);
        setSpecializations(updatedSpecializations);

    };

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const updatedSpecializations = [...specializations];
        updatedSpecializations[index][name] = value;
        setSpecializations(updatedSpecializations);
    };

    const fetchCategories = async () => {
        try {
            await API({
                url: `${apiURl.serviceCategories}`,
                method: "GET",
            }).then((data) => {
                if (data?.status) {
                    setCategories(data?.result);
                    specializations[0]["categories"] = data?.result;
                    setSpecializations(specializations);
                } else {
                    setCategories([]);
                }
            });
        } catch (error) {
            // throw error;
            toast.error(error);
        }
    };

    const fetchSubCategories = async (ServiceCategoryId, index) => {
        try {
            await API({
                url: `${apiURl.serviceSubCategories}?ServiceCatId=${ServiceCategoryId}`,
                method: "GET",
            }).then((data) => {
                if (data?.status) {
                    setSubCategories(data?.result);
                    specializations[index]["subCategories"] = data?.result;
                    setSpecializations(specializations);
                } else {
                    setSubCategories([]);
                }
            });
        } catch (error) {
            // throw error;
            toast.error(error);
        }
    };

    const fetchDetails = async (ServiceCategoryId, ServiceSubCategoryId, index) => {
        try {
            await API({
                url: `${apiURl.serviceDetails}?ServiceCatId=${ServiceCategoryId}&ServiceSubCatId=${ServiceSubCategoryId}`,
                method: "GET",
            }).then((data) => {
                if (data?.status) {
                    setDetails(data?.result);
                    specializations[index]["details"] = data?.result;
                    setSpecializations(specializations);

                } else {
                    setDetails([]);
                }
            });
        } catch (error) {
            // throw error;
            toast.error(error);
        }
    };

    const handleImageSelect = (e) => {
        const selectedFile = e.target.files[0];
        // setCoverImage(selectedFile);
        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setCoverImage(selectedFile);
                setCoverImageData(event.target.result)
                // onImageChange(selectedFile); // Pass the selected file to the parent component
            };
            reader.readAsDataURL(selectedFile);
        }
    };

    // useEffect(() => {
    //     if (specializations.length > 0) {
    //         // Fetch subcategories data based on the selected category for the first specialization
    //         fetchSubCategories(specializations[0].ServiceCatId, index);
    //     }
    // }, [specializations[0].ServiceCatId]);

    // useEffect(() => {
    //     if (specializations.length > 0 && specializations[0].ServiceSubCatId) {
    //         // Fetch details data based on the selected subcategory for the first specialization
    //         fetchDetails(specializations[0].ServiceCatId, specializations[0].ServiceSubCatId, index);
    //     }
    // }, [specializations[0].ServiceSubCatId]);

    const handleSubmit = async (e) => {
        const payload = {
            "FirstName": firstName,
            "LastName": lastName,
            "Email": email,
            "CountryPhoneCode": "+91",
            "Mobile": mobile,
            "CountryId": addressCountry,
            "StateId": addressState,
            "CityId": addressCity,
            "ZipCode": "08820",
            "Address": address,
            "AddressType": addressType,
            "WorkingCountryId": workCountry,
            "WorkingStateId": workState,
            "WorkingCityId": workCity,
            "WorkingAddressType": "8",
            "ServedArea": 8,
            "handymanServicesData": specializations
        }

        e.preventDefault();

        const formData = new FormData();

        formData.append('FirstName', firstName);
        formData.append('LastName', lastName);
        formData.append('Email', email);
        formData.append('CountryPhoneCode', '+91');
        formData.append('Mobile', mobile);
        formData.append('CountryId', addressCountry);
        formData.append('StateId', addressState);
        formData.append('CityId', addressCity);
        formData.append('ZipCode', '08820');
        formData.append('Address', address);
        formData.append('AddressType', addressType);
        formData.append('WorkingCountryId', workCountry);
        formData.append('WorkingStateId', workState);
        formData.append('WorkingCityId', workCity);
        formData.append('WorkingAddressType', 3);
        formData.append('ServedArea', 8);

        specializations.forEach((specialization, index) => {
            formData.append(`handymanServicesData[${index}][ServiceCatId]`, specialization.ServiceCatId);
            formData.append(`handymanServicesData[${index}][ServiceSubCatId]`, specialization.ServiceSubCatId);
            formData.append(`handymanServicesData[${index}][ServiceDetailId]`, specialization.ServiceDetailId);
            formData.append(`handymanServicesData[${index}][VisitingCharge]`, specialization.VisitingCharge);
            formData.append(`handymanServicesData[${index}][HourlyCharge]`, specialization.HourlyCharge);
        });

        formData.append('Image', coverImage);

        try {
            await API({
                url: `${apiURl.updatehandyman}`,
                method: "PUT",
                headers: {
                    Authorization: `${user?.token}`
                },
                body: formData,
                formData: true,
            }).then((data) => {
                if (data?.status == true) {
                    toast.success(data?.message);
                } else {
                    toast.error(data?.error);
                }
            });
        } catch (error) {
            // setApiErrors({ message: error.message });
            // throw error;
            toast.error(error);
        }


    };



    return (
        <>
            {loading ? (
                // Display a loader while loading
                <div>Loading...</div>) : (
                <section className='container py-5'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-10'>
                            <div className='handyManForm'>

                                {/* Basic form */}
                                <div className='basicInfo d-flex mb-3'>
                                    <figure className='me-4'><img src='/images/basic-info.png' /></figure>
                                    <div className='vchForm'>
                                        <div className='basicTitle mb-lg-4 mb-3'>
                                            <h6>Basic Info</h6>
                                            <p>Enter the event title, description</p>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-5 mb-3'>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    placeholder='First Name'
                                                    value={firstName}
                                                    onChange={(e) => setFirstName(e.target.value)}
                                                />
                                            </div>
                                            <div className='col-md-5 mb-3'>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    placeholder='Last Name'
                                                    value={lastName}
                                                    onChange={(e) => setLastName(e.target.value)}
                                                />
                                            </div>
                                            <div className='col-md-5 mb-3'>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    placeholder='Email address'
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                            </div>
                                            <div className='col-md-5 mb-3'>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    placeholder='Phone number'
                                                    value={mobile}
                                                    onChange={(e) => setMobile(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Handyman address area */}
                                <div className='basicInfo d-flex mb-3'>
                                    <figure className='me-4'><img src='/images/address.png' /></figure>
                                    <div className='vchForm'>
                                        <div className='basicTitle mb-lg-4 mb-3'>
                                            <h6>Address</h6>
                                            <p>Enter the address of Handyman</p>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-5 mb-3'>
                                                <select className='form-control' onChange={(e) => setAddressCountry(parseInt(e.target.value))} value={addressCountry}>
                                                    <option>Select Country</option>
                                                    {countries?.map((country) => (
                                                        <option key={country?.Id} value={country?.Id}>
                                                            {country?.Name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className='col-md-5 mb-3'>
                                                <select className='form-control' onChange={(e) => setAddressState(parseInt(e.target.value))} value={addressState}>
                                                    <option>Select State</option>
                                                    {states.map((st) => (
                                                        <option key={st?.Id} value={st?.Id}>
                                                            {st?.Name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className='col-md-5 mb-3'>
                                                <select className='form-control' onChange={(e) => setAddressCity(parseInt(e.target.value))} value={addressCity}>
                                                    <option>City</option>
                                                    {cities.map((ci) => (
                                                        <option key={ci?.Id} value={ci?.Id}>
                                                            {ci?.Name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className='col-md-5 mb-3'>
                                            <input type='text' className='form-control' placeholder='Zipcode'
                                                 value={zipCode}
                                              onChange={(e) => setZipcode(e.target.value)} />
                                            </div>
                                            <div className='col-md-10 mb-3'>
                                                <textarea
                                                    className='form-control'
                                                    placeholder='Address'
                                                    onChange={(e) => setAddress(e.target.value)}
                                                    value={address}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* image are */}
                                <div className="basicInfo d-flex mb-3">
                                    <figure className="me-4"><img src="/images/upload_image_icon.svg" alt="" /></figure>
                                    <div className="vchForm">
                                        <div className="basicTitle mb-lg-4 mb-3">
                                            <h6>Upload Cover Image <span>*</span></h6>
                                            <p>Upload discount offer images</p>
                                        </div>
                                        <div className="row pb-4">
                                            <div className="col-md-8 form-group">
                                                <div className="file-upload uploadImage">
                                                    <label htmlFor="upload" className="file-upload__label">
                                                        <div className="fileTitle d-flex align-items-center">
                                                            <figure><img src="/images/uploadIcon.svg" alt="" /></figure>
                                                            <div>
                                                                Click to Upload or drag and drop
                                                                <p>Svg, PNG, JPG or GIF (1000x600 max. 20mb)</p>
                                                            </div>
                                                        </div>
                                                    </label>
                                                    <input id="upload" className="file-upload__input" type="file" name="file-upload" onChange={handleImageSelect} />
                                                </div>
                                                {(!coverImage && coverImageData !== '') ? (
                                                    <ul className="upload_Image">
                                                        <li>
                                                            <img src={coverImageData} alt="" height={250} width={250} />
                                                            <button onClick={() => setCoverImage(null)}><FaTimes /></button>
                                                        </li>
                                                    </ul>
                                                ) : (coverImage) && (<ul className="upload_Image">
                                                    <li>
                                                        <img src={URL.createObjectURL(coverImage)} alt="" height={250} width={250} />
                                                        <button onClick={() => setCoverImage(null)}><FaTimes /></button>
                                                    </li>
                                                </ul>)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Working address area */}
                                <div className='basicInfo d-flex mb-3'>
                                    <figure className='me-4'><img src='/images/workArea.png' /></figure>
                                    <div className='vchForm'>
                                        <div className='basicTitle mb-lg-4 mb-3'>
                                            <h6>Working Area</h6>
                                            <p>Enter the address of Handyman</p>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-5 mb-3'>
                                                <select className='form-control' onChange={(e) => setWorkCountry(parseInt(e.target.value))} value={workCountry}>
                                                    <option>Select Country</option>
                                                    {countries.map((country) => (
                                                        <option key={country?.Id} value={country?.Id}>
                                                            {country?.Name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className='col-md-5 mb-3'>
                                                <select className='form-control' onChange={(e) => setWorkState(parseInt(e.target.value))} value={workState}>
                                                    <option>State</option>
                                                    {workStates.map((st) => (
                                                        <option key={st?.Id} value={st?.Id}>
                                                            {st?.Name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className='col-md-5 mb-3'>
                                                <select className='form-control' onChange={(e) => setWorkCity(parseInt(e.target.value))} value={workCity}>
                                                    <option>City</option>
                                                    {workCities.map((ci) => (
                                                        <option key={ci?.Id} value={ci?.Id}>
                                                            {ci?.Name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-md-8 mb-3">
                                                <label>Served Area</label>
                                                <div className="rangeBar">
                                                    <input type="range" />
                                                    <p className="note">
                                                        Distance served from location (radius in miles)
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Specialization area */}
                                <div className='basicInfo d-flex mb-3'>
                                    <figure className='me-4'><img src='/images/specialization.png' /></figure>
                                    <div className='vchForm'>
                                        <div className='basicTitle mb-lg-4 mb-3'>
                                            <h6>Specialization</h6>
                                            <p>Enter the address of Handyman</p>
                                        </div>

                                        {specializations.map((specialization, index) => (
                                            <div className='row' key={index}>
                                                <div className='col-md-4 mb-3'>
                                                    <select
                                                        className='form-control'
                                                        // name={`specialization[${index}].ServiceCatId`}
                                                        name={`ServiceCatId`}
                                                        value={specialization.ServiceCatId}
                                                        onChange={
                                                            (e) => {
                                                                handleInputChange(e, index);
                                                                fetchSubCategories(e.target.value, index);
                                                            }
                                                        }
                                                    >
                                                        <option>Select Category</option>
                                                        {specialization.categories.map((category) => (
                                                            <option key={category.ServiceCategoryLanguage.Id} value={category.ServiceCategoryLanguage.ServiceCategoryId}>
                                                                {category.ServiceCategoryLanguage.Title}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className='col-md-4 mb-3'>
                                                    <select
                                                        className='form-control'
                                                        // name={`specialization[${index}].ServiceSubCatId`}
                                                        name={`ServiceSubCatId`}
                                                        value={specialization.ServiceSubCatId}
                                                        onChange={(e) => {
                                                            handleInputChange(e, index);
                                                            fetchDetails(specialization?.ServiceCatId, e.target.value, index);
                                                        }}
                                                    >
                                                        <option>Select Sub Category</option>
                                                        {specialization.subCategories.map((subcategory) => (
                                                            <option key={subcategory.ServiceSubCategoryLanguage.Id} value={subcategory.ServiceSubCategoryLanguage.ServiceSubCategoryId}>
                                                                {subcategory.ServiceSubCategoryLanguage.Title}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className='col-md-4 mb-3'>
                                                    <select
                                                        className='form-control'
                                                        // name={`specialization[${index}].ServiceDetailId`}
                                                        name={`ServiceDetailId`}
                                                        value={specialization.ServiceDetailId}
                                                        onChange={(e) => handleInputChange(e, index)}
                                                    >
                                                        <option>Select Service</option>
                                                        {specialization.details.map((detail) => (
                                                            <option key={detail.ServiceDetailLanguage.Id} value={detail.ServiceDetailLanguage.ServiceDetailId}>
                                                                {detail.ServiceDetailLanguage.Title}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <div className='col-md-4 mb-3'>
                                                    <label>Base Price</label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        placeholder='$200'
                                                        // name={`specialization[${index}].VisitingCharge`}
                                                        name={`VisitingCharge`}
                                                        value={specialization.VisitingCharge}
                                                        onChange={(e) => handleInputChange(e, index)}
                                                    />
                                                </div>
                                                <div className='col-md-4 mb-3'>
                                                    <label>Per Hour Price</label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        placeholder='$50'
                                                        // name={`specialization[${index}].HourlyCharge`}
                                                        name={`HourlyCharge`}
                                                        value={specialization.HourlyCharge}
                                                        // onChange={handleInputChange}
                                                        onChange={(e) => handleInputChange(e, index)}
                                                    />
                                                </div>
                                                {specializations.length > 1 && (
                                                    <div className='col-md-4 mb-3 d-flex align-items-center justify-content-center my-auto'>
                                                        <button className='removeBtn d-flex align-items-center' onClick={() => handleRemoveField(index)}><FaCircleMinus className='me-2' />Remove</button>
                                                    </div>
                                                )}

                                                {specializations.length > 1 && index !== specializations.length - 1 && (
                                                    <div className="col-12"><hr className="my-5" /></div>
                                                )}
                                            </div>
                                        ))}
                                        <div className="row">
                                            <div className='col-12 mb-3 d-flex align-items-center justify-content-center my-auto'>
                                                <button className='addBtn d-flex align-items-center' onClick={handleAddField}><FaCirclePlus className='me-2' /> Add More</button>
                                            </div>

                                            <div className='col-12'>
                                                <div className='nextPreviousButton subBtn mt-5'>
                                                    <button type="btn" className="can d-flex justify-content-between align-items-center"><img src='/images/rightArrow.svg' />Cancel</button>
                                                    <button type="btn" className="sub ms-auto d-flex justify-content-between align-items-center" onClick={handleSubmit}>Save Info <img src='/images/arrow-right.svg' /></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >)}

        </>
    )
}

export default JoinHandyman;
