import React, { useEffect, useState } from 'react'

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { API } from '../apiwrapper';
import apiURl from '../../Store/Action/api-url';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Pagination from 'react-bootstrap/Pagination';
function YourReservationsOrder() {

    const [cstab, setCstab] = useState();
    const user = useSelector((state) => state?.user);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [reservationsOngoing, setReservationsOngoing] = useState();
    const [reservationsComplete, setReservationsComplete] = useState();

    const Ongoing = async () => {
        try {
            setLoading(true);
            await API({
                url: `${apiURl.reservationsongoing}?page=${page}&pageSize=${10}`,

                method: "GET",
                headers: {
                    Authorization: `${user?.token}`,
                    Accept: "application/json",
                },
            }).then((data) => {
                if (data?.status === true) {
                    console.log(data, "njjjhhhh")
                    setTotalPages(data?.totalPages);
                    setReservationsOngoing(data);
                }
            });
        } catch (error) {

            setLoading(false);
            toast.error(error);
        }
    };


    const Complete = async () => {
        try {
            setLoading(true);
            await API({
                url: `${apiURl.reservationcomplete}?page=${page}&pageSize=${10}`,

                method: "GET",
                headers: {
                    Authorization: `${user?.token}`,
                    Accept: "application/json",
                },
            }).then((data) => {
                if (data?.status === true) {
                    // console.log(data.orders[0].order[0].orderDetail, "njjjhhhh")
                    setReservationsComplete(data);
                }
            });
        } catch (error) {

            setLoading(false);
            toast.error(error);
        }
    }
    useEffect(() => {
        Ongoing();
        Complete();
    }, [page])

    return (
        <>
            <section className='reservOrder py-5'>
                <div className='container'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h1>Welcome, {user && user?.UserDettails?.FirstName} {user && user?.UserDettails?.LastName}!</h1>
                        <Link to="/personalInfo">

                            <button className='compProfile btn' >Complete your Profile</button>
                        </Link>
                    </div>
                    <div className='ongoingTable'>
                        <h3 className='subTitle'>Your reservations</h3>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Nav variant="pills" className="d-flex">
                                <Nav.Link eventKey="first" onClick={Ongoing}>Ongoing</Nav.Link>
                                <Nav.Link eventKey="second" onClick={Complete}>Complete</Nav.Link>
                            </Nav>

                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <div className='ongongTable'>
                                        <table class="table">
                                            <thead class="table-light">
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Address</th>
                                                    <th>Service Name</th>
                                                    <th>Date</th>
                                                    <th>Time</th>
                                                    <th>Price</th>
                                                    <th>&nbsp;</th>
                                                </tr>
                                            </thead>
                                            <tbody>


                                                {/* {reservationsOngoing && reservationsOngoing?.orders?.map((orderObject) =>
                                                    orderObject?.order.map((ele) => (
                                                        <tr>
                                                            <td>{reservationsOngoing && reservationsOngoing?.user?.FullName}</td>


                                                            <>
                                                                <td>{ele.orderDetail?.Address}</td>
                                                                <td>{ele.orderDetail?.ServiceName}</td>
                                                                <td>{ele.orderDetail?.ServiceDate}</td>
                                                                <td>{ele.orderDetail?.ServiceTime}</td>
                                                                <td>${ele.orderDetail?.TotalPrice}</td>
                                                            </>

                                                             <td><button>Cancel</button></td> 
                                                        </tr>
                                                    ))
                                                )} */}

                                                {reservationsOngoing && reservationsOngoing?.orders?.map((orderObject) =>
                                                    <tr>
                                                        <td>{orderObject?.User?.FirstName} {orderObject?.User?.LastName}</td>
                                                        <td>{orderObject?.Order?.Address}</td>
                                                        <td>{orderObject?.ServiceDetail?.ServiceDetailLanguage?.Title}</td>
                                                        <td>{orderObject?.ServiceDate}</td>
                                                        <td>{orderObject?.ServiceTime}</td>
                                                        <td>${orderObject?.Order?.TotalPrice}</td>
                                                        {/* <td><button>Cancel</button></td> */}
                                                    </tr>

                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <Pagination>


                                        <Pagination.First onClick={() => setPage(1)} disabled={page === 1} />
                                        <Pagination.Prev onClick={() => setPage(page - 1)} disabled={page === 1} >Prev</Pagination.Prev>
                                        {page}
                                        <Pagination.Next onClick={() => setPage(page + 1)} disabled={page === totalPages} >Next</Pagination.Next>
                                        <Pagination.Last onClick={() => setPage(totalPages)} disabled={page === totalPages} />
                                    </Pagination>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <div className='ongongTable'>
                                        <table class="table">
                                            <thead class="table-light">
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Address</th>
                                                    <th>Service Name</th>
                                                    <th>Date</th>
                                                    <th>Time</th>
                                                    <th>Price</th>
                                                    <th>&nbsp;</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* {reservationsComplete && reservationsComplete?.orders?.map((orderObject) =>

                                                    orderObject?.order.map((ele) => (

                                                        <tr>
                                                            <td>{reservationsOngoing && reservationsOngoing?.user?.FullName}</td>
                                                            <>
                                                                <td>{ele.orderDetail?.Address}</td>
                                                                <td>{ele.orderDetail?.ServiceName}</td>
                                                                <td>{ele.orderDetail?.ServiceDate}</td>
                                                                <td>{ele.orderDetail?.ServiceTime}</td>
                                                                <td>${ele.orderDetail?.TotalPrice}</td>
                                                            </>

                                                        </tr>


                                                    ))

                                                )  } */}

                                                {reservationsComplete && reservationsComplete?.orders?.map((orderObject) =>

                                                    <tr>
                                                        <td>{orderObject?.User?.FirstName} {orderObject?.User?.LastName}</td>
                                                        <td>{orderObject?.Order?.Address}</td>
                                                        <td>{orderObject?.ServiceDetail?.ServiceDetailLanguage?.Title}</td>
                                                        <td>{orderObject?.ServiceDate}</td>
                                                        <td>{orderObject?.ServiceTime}</td>
                                                        <td>${orderObject?.Order?.TotalPrice}</td>
                                                    </tr>

                                                )}

                                            </tbody>
                                        </table>
                                    </div>
                                    <Pagination>


                                        <Pagination.First onClick={() => setPage(1)} disabled={page === 1} />
                                        <Pagination.Prev onClick={() => setPage(page - 1)} disabled={page === 1} >Prev</Pagination.Prev>
                                        {page}
                                        <Pagination.Next onClick={() => setPage(page + 1)} disabled={page === totalPages} >Next</Pagination.Next>
                                        <Pagination.Last onClick={() => setPage(totalPages)} disabled={page === totalPages} />
                                    </Pagination>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </section>
        </>
    )
}

export default YourReservationsOrder