// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
// // import HttpApi from "i18next-http-backend";

// i18n
//   .use(initReactI18next)
//   .use(LanguageDetector)
//   .use(HttpApi)
//   .init({
//     // lng: document.querySelector("html").lang,ssss
//     supportedLngs: ["en", "es"],
//     fallbackLng: "en",
//     detection: {
//       order: [
//         "cookie",
//         "localStorage",
//         "path",
//         "htmlTag",
//         "subdomain",
//         "navigator",
//         "querystring",
//         "sessionStorage",
//       ],
//       caches: ["cookie"],
//     },

//     backend: { loadPath: "/assets/locales/{{lng}}/translation.json" },
//   });


import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import EN from "../i18n/locales/en/English.json";
import ES from "../i18n/locales/es/Spanish.json";
// import HI from "./Hindi.json";

const getLanguageFromLocalStorage = () => {
    const storedLanguage = localStorage.getItem("langs");
    // If a language is stored in local storage, use it; otherwise, use a default language
    return storedLanguage || "en";
};

const selectedLanguage = getLanguageFromLocalStorage();


i18n
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources: {
            en: { translation: EN },
            es: { translation: ES },
            // hi: { translation: HI },
        },
        lng: selectedLanguage,
        fallbackLng: "en",
        // debug: true,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

export default i18n;
