const apiURl = {
  servicecategory: "/servicecategory",
  servicesubcategory: "/servicesubcategory",
  servicesubcategory: "/servicesubcategory",
  otpverify: "/otpverify",
  sentotp: "/sentotp",
  getuseraddress: "/getuseraddress",
  adduseraddress: "/adduseraddress",
  countries: "/country",
  stateLists: "/state",
  cityLists: "/city",
  serviceCategories: "/servicecategory",
  serviceSubCategories: "/servicesubcategory",
  serviceDetails: "/servicedetails",
  userDetais: "/user-details",
  updatehandyman: "/updatehandyman",
  addtocart: "/cart",
  cartlisting: "/cart",
  editaddress:"/updateuseraddress",
  order:"/processorder",
  deletecart:"/cart",
  updateuser: "/updateuser",
  reservationsongoing:"/order-list-pending",
  reservationcomplete:"/order-list-complete",
  sociallogin:"/sociallogin",
  servicesearch:"/searchServiceCategory",
};

export default apiURl;
