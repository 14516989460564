import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer, REGISTER, REHYDRATE, FLUSH, PAUSE, PERSIST, PURGE } from "redux-persist";
import storage from "redux-persist/lib/storage";
import PopupReducer from "./reducer/PopupReducer";
import CartReducer from "./reducer/Cart";
import UserReducer from "./reducer/UserReducer";

const persistConfig = {
  key: "root",
  storage,
};

const reducer = {
  PopupReducer: PopupReducer,
  user: UserReducer,
  cart: CartReducer
};

const rootReducer = combineReducers(reducer);

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

const persistor = persistStore(store);

export { store, persistor };

