import React, { useEffect, useState } from "react";
import "./css/login.css";
import { API } from "../apiwrapper";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
import { SetUserData } from "../../Store/reducer/user";
import { useDispatch } from "react-redux";
import apiURl from "../../Store/Action/api-url";

import Modal from "react-bootstrap/Modal";
import { SetpopupReducerData } from "../../Store/reducer/PopupReducer";
import { FcGoogle } from "react-icons/fc";
import { FaFacebookF } from "react-icons/fa6";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { SetUserDettails, login } from "../../Store/reducer/UserReducer";
import FacebookLogin from 'react-facebook-login';

function Login() {
  const [loginType, setLoginType] = useState("email");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingResendotp, setIsLoadingResendotp] = useState(false);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [show, setShow] = useState(true);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const handleClosepopup = () => {
    dispatch(SetpopupReducerData({ modalType: "", showModal: false }));
  };
  const handleLoginTypeSwitch = (e) => {
    e.preventDefault();
    setLoginType(loginType === "email" ? "phone" : "email");
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    if (value === "" || /^[0-9\b]+$/.test(value)) {
      setPhone(value);
    }
  };

  const handleOtpChange = (element, index) => {
    if (isNaN(element.value)) return false;
    const newOtp = [...otp];
    console.log(newOtp, "newOtpnewOtp");
    newOtp[index] = element.value;
    setOtp(newOtp);

    if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }
  };

  const handleCountryCodeChange = (e) => {
    setCountryCode(e.target.value);
  };



  const LoginAPI = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await API({
        url: `${apiURl.sentotp}`,
        method: "POST",
        body: { Email: email },
        formData: false,
      }).then((data) => {
        if (data?.status == true) {
          handleClosepopup();
          setShow(false);
          dispatch(
            SetpopupReducerData({
              modalType: "OTP",
              showModal: true,
              Email: email,
            })
          );
          toast.success(data?.message);

        } else {
          // setApiErrors({ message: data?.message });
          toast.error(data?.error);
        }
      });
    } catch (error) {
      // setApiErrors({ message: error.message });
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  // const resend = async (e) => {
  //   e.preventDefault();
  //   setIsLoadingResendotp(true);

  //   try {
  //     await API({
  //       url: `${apiURl.sentotp}`,
  //       method: "POST",
  //       body: { Email: email },
  //       formData: false,
  //     }).then((data) => {
  //       if (data?.status == true) {
  //         toast.success(data?.message);
  //         // setShowOtpModal(true);
  //       } else {
  //         // setApiErrors({ message: data?.message });
  //         toast.error(data?.error);
  //       }
  //     });
  //   } catch (error) {
  //     // setApiErrors({ message: error.message });
  //     throw error;
  //   } finally {
  //     setIsLoadingResendotp(false);
  //   }
  // };

  // const handleOtpSubmit = async (e) => {
  //   e.preventDefault();
  //   setIsLoading(true);
  //   const otpValue = otp.join("");
  //   try {
  //     await API({
  //       url: `${apiURl.otpverify}`,
  //       method: "POST",
  //       body: {
  //         Otp: otpValue,
  //         Email: email,
  //         CountryPhoneCode: "",
  //         Mobile: "",
  //       },
  //       formData: false,
  //     }).then((data) => {
  //       if (data?.status == true) {
  //         toast.success(data?.message);
  //         console.log(data?.result, "dadadad");
  //         dispatch(SetUserData({ ...data }));
  //         localStorage.setItem("token", data?.result);

  //         setOtp("");
  //         setOtp(new Array(6).fill(""));
  //         // navigate("/");
  //       } else {
  //         // setApiErrors({ message: data?.message });
  //         toast.error(data?.error);
  //       }
  //     });
  //   } catch (error) {
  //     // setApiErrors({ message: error.message });
  //     throw error;
  //   } finally {
  //     setIsLoading(false);
  //   }
  //   setShowOtpModal(false);
  // };

  // const otpModal = showOtpModal && (
  //   <div className="otpModal">
  //     <div className="otpModalContent">
  //       <span className="close" onClick={() => setShowOtpModal(false)}>
  //         &times;
  //       </span>
  //       <h2>Please Verify</h2>
  //       <p>
  //         Please enter 6 digit otp sent on your email address or mobile number{" "}
  //         <a className="link">{email}</a>
  //       </p>
  //       <div className="d-flex" id="otp">
  //         {otp.map((data, index) => (
  //           <input
  //             key={index}
  //             type="text"
  //             className="otpInput"
  //             maxLength="1"
  //             value={data}
  //             onChange={(e) => handleOtpChange(e.target, index)}
  //           />
  //         ))}
  //       </div>
  //       <div className="text-center mt-3">
  //         <Button variant="primary" onClick={handleOtpSubmit}>
  //           Verify OTP
  //         </Button>
  //         <p className="link">
  //           <a className="pt-2 d-inline-block" onClick={resend}>
  //             {isLoadingResendotp ? "Resend Otp..." : "Resend Otp"}
  //           </a>
  //         </p>
  //       </div>
  //     </div>
  //   </div>
  // );

  // const googleLogin = useGoogleLogin({
  //   onSuccess: (tokenResponse) => setUser(tokenResponse),
  // });

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => setUser(tokenResponse),
  });





  const fetchData = async () => {
    try {
      const userInfoRes = await axios.get(
        `https://www.googleapis.com/oauth2/v2/userinfo?access_token=${user.access_token}`,
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: "application/json",
          },
        }
      );

      const obj = userInfoRes?.data;

      console.log(obj);

      const payload = {
        FirstName: obj?.given_name,
        LastName: obj?.family_name,
        Email: obj?.email,
        Image: obj?.picture,
        GId: obj?.id,
        Method: "GOOGLE",
      };

      console.log(payload, "data");

      const response = await API({
        url: apiURl.sociallogin,
        method: "POST",
        body: payload,
        formData: false,
      });

      if (response?.status) {

        console.log(response.data, "sociallogindata");
        dispatch(login({ data: { result: response?.data?.token } }));
        localStorage.setItem("token", response?.data?.token);

        console.log("abb");

        try {
          setLoading(true);

          API({
            url: `${apiURl.userDetais}`,
            method: "GET",
            headers: {
              Authorization: `${response?.data?.token}`,
              Accept: "application/json",
            },
          }).then((data) => {
            if (data?.status === true) {
              dispatch(SetUserDettails(data?.data));
            }
          });
        } catch (error) {

          setLoading(false);
          toast.error(error);
        }

        toast.success(response.message);
        handleClosepopup();
        setShow(false);

      }

    }
    catch (err) {
      throw err;
    }
  };


  useEffect(() => {
    if (user) {
      fetchData();
    }
  }, [user]);

  const  responseFacebook = async (response) => {
    console.log(response,"facebook data");

    const payload = {
      FirstName: response?.name,
      Email: response?.email,
      SocialId: response?.id,
      Method: "FACEBOOK",
    };

    const resp = await API({
      url: apiURl.sociallogin,
      method: "POST",
      body: payload,
      formData: false,
    });
  
    console.log(payload, "facebook payload");

    if (resp?.status) {

      console.log(resp.data, "facebooklogindata");
      dispatch(login({ data: { result: resp?.data?.token } }));
      localStorage.setItem("token", resp?.data?.token);


      try {
        setLoading(true);

        API({
          url: `${apiURl.userDetais}`,
          method: "GET",
          headers: {
            Authorization: `${resp?.data?.token}`,
            Accept: "application/json",
          },
        }).then((data) => {
          if (data?.status === true) {
            dispatch(SetUserDettails(data?.data));
          }
        });
      } catch (error) {

        setLoading(false);
        toast.error(error);
      }

      toast.success(response.message);
      handleClosepopup();
      setShow(false);

    }



  };


  return (
    <>
      <Modal show={show} className="loginPoup" onHide={handleClosepopup}  >
        <Modal.Body className="py-4">
          <h3>Sign In / Sign up</h3>
          <div className="undefined mt-3">
            {loginType === "email" ? (
              <div className="form-group">
                <input
                  type="text"
                  name="Email"
                  className="form-control"
                  placeholder="Enter Your Email ID"
                  value={email}
                  onChange={handleEmailChange}
                />
              </div>
            ) : (
              <div className="row gx-2">
                <div className="col-xl-3">
                  <select
                    className="form-control"
                    value={countryCode}
                    onChange={handleCountryCodeChange}
                  >
                    <option value="">Select</option>
                    <option value="91">+91</option>
                    <option value="1">+1</option>
                  </select>
                </div>
                <div className="col-xl-9">
                  <input
                    type="tel"
                    className="form-control"
                    name="Phone"
                    placeholder="Enter Your Phone Number"
                    value={phone}
                    onChange={handlePhoneChange}
                    pattern="\d*"
                  />
                </div>
              </div>
            )}
            <div className="d-flex justify-content-between align-items-center mt-3">
              <a className="text-end" href="#" onClick={handleLoginTypeSwitch}>
                <u>{loginType === "email" ? "Use Phone Number" : "Use Email ID"}</u>
              </a>
              <button className="continue" onClick={LoginAPI}>{isLoading ? "Loading..." : "LOGIN"}</button>
            </div>
          </div>
          <div className="orWith my-4">Or Continue with</div>
          <div className="socalLogin my-2">
            <button className="gmBtn" onClick={() => googleLogin()} ><span><FcGoogle /></span> Continue with Google</button>
            {/* <button className="faBtn"><span><FaFacebookF /></span> Continue with Facebook</button> */}
            <FacebookLogin
              appId="694031926200584" 
               autoLoad={false}
              fields="id,name,email,picture"
              callback={responseFacebook}
               cssClass="faBtn"
              icon={<FaFacebookF />}
              textButton=" Continue with Facebook"
            />

          </div>
        </Modal.Body>
      </Modal>
      {/* {otpModal} */}
    </>
  );
}

export default Login;
