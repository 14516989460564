import React, { useEffect, useState } from "react";
import { FaStar, FaLuggageCart } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import ServicesDetailCard from "./ServicesDetailCard";
import { API } from "../apiwrapper";
import apiURl from "../../Store/Action/api-url";
function ServicesCategories({ servicesDetails, initialServiceSubCatId }) {
  const [categoryDetails, setCategoryDetails] = useState([]);
  const { id } = useParams();
  const [ServiceSubCatId, setServiceSubCatId] = useState();
  //  console.log(categoryDetails,"categoryDetails")

  useEffect(() => {
    setCategoryDetails(servicesDetails);
    setServiceSubCatId(initialServiceSubCatId);
  }, [servicesDetails, initialServiceSubCatId]);

  const [servicesSubDetails, setServicesSubDetails] = useState([]);
  useEffect(() => {
    const fetchServicesDettals = async () => {
      try {
        await API({
          url: `${apiURl.servicesubcategory}?ServiceCatId=${id}`,
          method: "GET",
        }).then((data) => {
          if (data?.status) {
            setServicesSubDetails(data?.result);
          } else {
            setServicesSubDetails([]);
          }
        });
      } catch (error) {
        throw error;
      }
    };
    fetchServicesDettals();
  }, [id]);

  console.log(ServiceSubCatId, "ServiceSubCatId");

  return (
    <>
      <div className="serviceList">
        <h4>Select a Service</h4>
        <ul className="sercat">
          {categoryDetails?.map((ele, index) => (

            <li
              key={index}
              className="position-relative"
              onClick={() => {
                setServiceSubCatId(ele?.ServiceSubCategoryId);
              }}
            >
              <div className="quckbook">
                <img src={ele?.Image} alt="subcategoryimage" />
              </div>
              <h6>{ele?.ServiceSubCategoryLanguage?.Title}</h6>
              {/* <Link className="stretched-link" to="/"></Link> */}
            </li>

          ))}
        </ul>
      </div>
      <ServicesDetailCard servicesSubDetails={servicesSubDetails} serviceCategoryId={id} serviceSubCatId={ServiceSubCatId} />
    </>
  );
}

export default ServicesCategories;
